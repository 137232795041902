import PracticeArea from 'resources/practice-area';

class FeatureLab {
  static isScout = false;
  static isFeatureLab = true;

  static queryPracticeAreas() {
    return PracticeArea.query({ view: 'base' });
  }
}

export default FeatureLab;
