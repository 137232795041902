import BaseResource from 'resources/base-resource';
import { filter, find } from 'lodash';
import Appointment from 'resources/marketplace/appointment.js';
import TimeZoneHelper from 'vue-app/shared/services/time-zone-helper.js';

class Consultation extends BaseResource {
  static baseUrl = '/consultations/:id.json';

  static scheduledTimesFor(user) {
    return this.query({ currentAndFuture: true }).then((consultations) => {
      let startsAt = null;
      let key = null;

      return consultations.reduce((acc, c) =>  {
        startsAt = TimeZoneHelper.convertToMomentUseRailsTimeZone(c.startsAt, user.timeZone).utc();
        key = startsAt.format('YYYY-MM-DD');

        acc[key] = acc[key] || [];
        acc[key].push(startsAt.format('HH:mm'));

        return acc;
      }, {});
    });
  }

  setTimeZone(timezone) {
    const startsAt = TimeZoneHelper.convertToMomentUseRailsTimeZone(this.startsAt, timezone);

    this.startsAtTime = startsAt.format('h:mm A z');
    this.startsAtDay  = startsAt.format('dddd');
    this.startsAtDate = startsAt.format('MMMM D, Y');
  }

  participantsOtherThan(user) {
    return filter(this.appointmentParticipants, (ap) => {
      return !(ap.participantId === user.id && ap.participantType === user.klass);
    });
  }

  activityFor(user) {
    const participant = find(this.appointmentParticipants, (ap) => {
      return ap.participantId === user.id && ap.participantType === user.klass;
    });

    return this.participantActivityFor(participant);
  }

  clientParticipant() {
    return find(this.appointmentParticipants, { participantType: 'Client' });
  }

  lawyerParticipant() {
    return find(this.appointmentParticipants, { participantType: 'Lawyer' });
  }

  participantActivityFor(participant) {
    return find(this.participantActivity, { appointmentParticipantId: participant.id });
  }

  loadAppointment() {
    if (this.appointment) { return Promise.resolve(this.appointment); }

    return Appointment.get({ id: this.appointmentId }).then((appt) => {
      this.appointment = appt;
      return appt;
    });
  }
}

export default Consultation;
