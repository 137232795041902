<template>
  <div :class="['messaging-center-list-item pointer', previewClass, { 'draft': !isUnread && hasDraftSubject, 'unread': isUnread, 'read': !isUnread }]" @click="selectConversation">
    <div class="item-photo">
      <div class="photo-container single" v-if="participants.length === 1">
        <profile-photo :user="participants[0]" :small="true"></profile-photo>
      </div>

      <div class="photo-container multiple" v-if="participants.length > 1">
        <div class="inner-container first">
          <profile-photo :user="participants[0]" :smallest="true"></profile-photo>
        </div>

        <div class="inner-container second">
          <profile-photo :user="participants[1]" :smallest="true"></profile-photo>
        </div>
      </div>
    </div>

    <div class="item-content">
      <div class="bottom-5">
        <div class="content-left text-ellipsis">
          <span v-for="(participant, index) in participantDisplays" :key="`${participant.klass}-${participant.id}`">
            <span class="semibold-weight">{{ participant.name }}{{ (index === participantDisplays.length - 1) || participant.role ? '' : ', ' }}</span>
            <span class="text-italic" v-if="participant.role">({{ participant.role }}){{ (index === participantDisplays.length - 1) ? '' : ', ' }}</span>
          </span>
        </div>

        <div class="content-right">
          <span class="size-text-11px dark-gray-text semibold-weight">{{ lastMessageSentAt }}</span>
        </div>
      </div>

      <div class="text-ellipsis">
        <span v-if="!hasDraftSubject || isUnread">{{ conversation.subject }}</span>
        <span v-else class="dark-gray-text text-italic">(Draft) {{ conversation.draftSubject }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import ProfilePhoto from 'vue-app/shared/components/profile-photo.vue';
import { DateTime } from 'luxon';

export default {
  name: 'ConversationPreview',

  components: {
    ProfilePhoto
  },

  props: {
    conversation: {
      type: Object,
      required: true
    },

    userClass: {
      type: String,
      required: true
    },

    onSelect: {
      type: Function,
      required: true
    }
  },

  computed: {
    previewClass() {
      return {
        admin:  'admin-preview',
        client: 'client-preview',
        lawyer: 'lawyer-preview'
      }[this.userClass];
    },

    hasDraftSubject() {
      return !!this.conversation.draftSubject;
    },

    isUnread() {
      return this.conversation.isUnread;
    },

    participants() {
      return this.conversation.participants;
    },

    participantDisplays() {
      return this.conversation.participantDisplays;
    },

    lastMessageSentAt() {
      const dt = DateTime.fromISO(this.conversation.lastMessageSentAt);

      if (!dt.isValid) { return ''; }

      if (DateTime.now().hasSame(dt, 'day')) {
        return dt.toLocaleString(DateTime.TIME_SIMPLE);
      }
      else if (DateTime.now().minus({ days: 1 }).hasSame(dt, 'day')) {
        return 'Yesterday';
      }
      else {
        return dt.toLocaleString(DateTime.DATE_MED);
      }
    }
  },

  methods: {
    selectConversation() {
      this.onSelect(this.conversation);
    }
  }
};
</script>
