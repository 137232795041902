<template>
  <div>
    <div class="modal-header">
      <div>
        <div class="bottom-10">
          <participant-photos :participants="selectedParticipants"></participant-photos>
        </div>

        <div>
          Availability for {{ appointmentType }}
        </div>
      </div>
    </div>

    <div class="appointment-container">
      <availability-selector
        ref="availabilitySelector"
        :availability="availability">
      </availability-selector>
    </div>
  </div>
</template>

<script>
import ParticipantPhotos from 'vue-app/marketplace/shared/appointments/participant-photos.vue';
import AvailabilitySelector from 'vue-app/marketplace/shared/appointments/availability-selector.vue';

export default {
  name: 'StepAvailability',

  components: {
    ParticipantPhotos,
    AvailabilitySelector
  },

  props: {
    appointment: {
      type: Object,
      required: true
    },

    availability: {
      type: Object,
      required: true
    },

    selectedParticipants: {
      type: Array,
      required: true
    }
  },

  computed: {
    appointmentType() {
      return this.appointment.typeText();
    }
  },

  methods: {
    reset() {
      this.$refs.availabilitySelector.reset();
    }
  }
};
</script>
