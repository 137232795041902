<template>
  <div class="messaging-action-bar">
    <div class="row">
      <div class="col-xs-7 col-sm-8">
        <div v-show="!isRefreshing" class="pointer svg-button size-text-11px" @click="onRefreshMessaging">
          <div class="text-left">
            <svg-icon name="refresh" class="base-icon smaller right-5"></svg-icon><span class="semibold-weight">Refresh Messages</span>
          </div>
        </div>

        <div v-show="isRefreshing" class="size-text-11px">
          <svg-icon name="refresh" class="base-icon smaller right-5"></svg-icon><span class="semibold-weight">Loading...</span>
        </div>
      </div>

      <div class="col-xs-5 col-sm-4">
        <div class="svg-button size-text-11px" @click="onCloseMessaging">
          <div class="text-right">
            Close Window
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import LoadingService from 'vue-app/shared/services/loading-service.js';

export default {
  name: 'ActionBar',

  components: {
    SvgIcon
  },

  props: {
    onRefreshMessaging: {
      type: Function,
      required: true
    },

    onCloseMessaging: {
      type: Function,
      required: true
    }
  },

  computed: {
    isRefreshing() {
      return LoadingService.isLoading('receipts');
    }
  }
};
</script>
