<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="-269 20.2 360 360.8"><path class="p-st2" d="M-103.1 255.3l-61.1-57.7c-6.6-6.3-6.9-16.7-.7-23.3 6.3-6.6 16.7-6.9 23.3-.7l49.2 46.5L63 59.6c6.4-6.5 16.8-6.7 23.3-.3s6.7 16.8.3 23.3L-80 254.8c-6.2 6.5-16.6 6.7-23.1.5z" /><path class="p-st3" d="M67.3 173.2c-6.9 2.5-11.3 9.4-10.8 16.8.2 3.4.4 6.9.4 10.4 0 81.4-66.6 147.6-148.1 147-80-.6-145.2-65.9-145.8-145.8-.6-81.6 65.6-148.1 147-148.1 23.2 0 45.2 5.4 64.7 15 7.8 3.9 17.3.9 21.6-6.7 4.7-8.3 1.4-18.8-7.2-23C-35 26.9-62.1 20.3-90.7 20.4c-98.6.3-179.2 80.9-179.4 179.6-.2 99.4 80.6 180.4 180 180.4 99.3 0 180-80.7 180-180 0-4.3-.2-8.6-.5-12.8-.7-10.9-11.8-18.1-22.1-14.4z" /></svg>
</template>

<script>
export default {
  name: 'SvgCheckmarkGreen'
};
</script>

<style scoped>
  .p-st2 {
    fill: #3daa2d;
  }

  .p-st3 {
    fill: #eee;
  }
</style>
