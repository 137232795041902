<template>
  <svg viewBox="0 0 54 54" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient x1="0%" y1="100%" x2="99.392%" y2=".608%" id="aig-a"><stop stop-color="#0A1370" offset="0%" /><stop stop-color="#9213B2" offset="99.945%" /></linearGradient><linearGradient x1=".015%" y1="100%" x2="99.378%" y2=".608%" id="aig-b"><stop stop-color="#0A1370" offset="0%" /><stop stop-color="#9213B2" offset="99.945%" /></linearGradient></defs><g fill="none" fill-rule="evenodd"><circle :stroke="absoluteUrl('aig-a')" stroke-width="2" cx="27" cy="27" r="26" /><path d="M29.936 17.003c.326 0 .596.114.786.384.199.283.23.59.083.901a.864.864 0 01-.738.514c-.085.008-.171.01-.257.01l-5.43.001h-5.546v16.373H35.17v-.105c0-3.833 0-7.666-.002-11.5 0-.263.08-.492.269-.675a.89.89 0 01.923-.22c.34.103.548.344.623.694a.965.965 0 01.016.194L37 35.698c0 .712-.569 1.29-1.281 1.304H18.352c-.486 0-.87-.194-1.145-.597a1.183 1.183 0 01-.206-.676V18.278c0-.624.508-1.192 1.125-1.26.085-.008.171-.014.257-.014h11.553zm-4.324 13.992h4.131a.93.93 0 01.608.204.888.888 0 01.282.909.866.866 0 01-.718.631 1.89 1.89 0 01-.21.013H21.56c-.317 0-.582-.108-.766-.371a.834.834 0 01-.075-.886.829.829 0 01.723-.493c.075-.006.15-.007.226-.007h3.944zm-1.754-4.835c.468 0 .847.342.89.803a.882.882 0 01-.74.942c-.057.009-.115.009-.172.009h-1.148v-.003c-.411 0-.823.007-1.234-.002a.863.863 0 01-.812-.715.874.874 0 01.657-1.007 1.02 1.02 0 01.24-.026 764.85 764.85 0 012.32-.001zm12.522-9.115a.833.833 0 01.603.68.842.842 0 01-.243.78l-6.321 6.511c-.4.413-.802.826-1.2 1.24-.307.32-.88.338-1.206.03a1685.497 1685.497 0 01-3.25-3.074c-.334-.317-.389-.782-.144-1.14a.882.882 0 011.346-.14c.856.812 1.714 1.62 2.571 2.43l.02.017c.125-.116.252-.225.368-.344 2.193-2.258 4.384-4.519 6.578-6.775.241-.247.548-.316.878-.215z" :fill="absoluteUrl('aig-b')" /></g></svg>
</template>

<script>
export default {
  name: 'SvgAddedItemGradient',

  methods: {
    absoluteUrl: function (id) {
      return `url(${window.location.href}#${id})`;
    }
  }
};
</script>
