import { isEmpty } from 'lodash';

class DateFilter {
  filter(value, formatOpts) {
    if (value) {
      const defaultOpts = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      };

      return new Intl.DateTimeFormat('en-US', formatOpts || defaultOpts).format(new Date(value));
    }
  }

  filterDate(value, formatOpts) {
    if (value) {
      formatOpts = formatOpts || {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      };
      formatOpts.timeZone = 'UTC';

      return new Intl.DateTimeFormat('en-US', formatOpts).format(new Date(value));
    }
  }

  // MMM d, YYYY
  longDate(value) {
    const formatOpts = {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    };

    return this.filter(value, formatOpts);
  }

  // yyyy-mm-dd
  isoDate(value) {
    if (value) {
      return new Date(value).toISOString().substring(0, 10);
    }
  }

  // mm/dd/yy
  shortDate(value) {
    const formatOpts = {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit'
    };

    return this.filter(value, formatOpts);
  }

  // m/d/yy, h:mm a
  short(value) {
    const formatOpts = {
      year: '2-digit',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit'
    };

    return this.filter(value, formatOpts);
  }

  // h:mm a
  shortTime(value) {
    const formatOpts = {
      hour: 'numeric',
      minute: '2-digit'
    };

    return this.filter(value, formatOpts);
  }

  // mm/dd/yyyy, hh:mm a
  long(value) {
    const formatOpts = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    };

    return this.filter(value, formatOpts);
  }

  longWithAt(value) {
    const valueStr = this.long(value);

    if (isEmpty(valueStr)) {
      return null;
    }
    else {
      return valueStr.split(',').join(' at');
    }
  }

  // yyyy
  year(value) {
    const formatOpts = {
      year: 'numeric',
      timeZone: 'UTC'
    };

    return this.filter(value, formatOpts);
  }
}

const instance = new DateFilter();

export default instance;
