<template>
  <div>
    <div class="notification-header tiny-header-text">
      Your Suggested {{ typeTextCapitalized }} Times Have Passed
    </div>

    <div class="notification-content">
      <div class="text-italic size-text-13px">
        The times you have suggested for your {{ typeText }} with <span class="semibold-weight">{{ otherParticipantsList }}</span> have passed.<span v-if="showButtons"> Please submit new times as soon as possible.</span>
      </div>

      <div class="top-20" v-if="showButtons">
        <button data-testid="reschedule-expired-availability" type="button" class="nv-button-blue smaller" @click="onUpdateAvailability(appointmentMetadata.id)">Respond To <span v-if="isVideoCall" class="hidden-xs">Video </span>{{ buttonText }} Request</button>
      </div>
    </div>
  </div>
</template>

<script>
import Appointment from 'resources/marketplace/appointment.js';

export default {
  name: 'LastAvailabilityExpiredNotification',

  props: {
    appointmentMetadata: {
      type: Object,
      required: true
    },

    onUpdateAvailability: {
      type: Function,
      required: true
    },

    showButtons: {
      type: Boolean,
      default: false
    },

    otherParticipantsList: {
      type: String,
      required: true
    }
  },

  computed: {
    appointmentResource() {
      return new Appointment(this.appointmentMetadata);
    },

    typeText() {
      return this.appointmentResource.typeText();
    },

    typeTextCapitalized() {
      return this.appointmentResource.buttonText();
    },

    isVideoCall() {
      return this.appointmentMetadata.type === 'video';
    },

    buttonText() {
      return ['phone', 'video'].includes(this.appointmentMetadata.type) ? 'Call' : 'Meeting';
    }
  }
};
</script>
