import axios from 'axios';
import BaseResource from 'resources/base-resource';

class Address extends BaseResource {
  static baseUrl = '/addresses/:id.json';

  static geocode(params) {
    return axios.post('/addresses/geocode.json', params);
  }
}

export default Address;
