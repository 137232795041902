import { defineStore } from 'pinia';
import { DateTime }    from 'luxon';
import TimeZoneHelper from 'vue-app/shared/services/time-zone-helper.js';
import Scout from 'resources/products/scout';
import FeatureLab from 'resources/products/feature-lab';
import User from 'resources/user';

const guessedIanaZone = function () {
  return DateTime.now().zoneName;
};

const guessRailsZone = function () {
  const guessedZone = TimeZoneHelper.tzInfoToTimeZoneMap[guessedIanaZone()];
  return guessedZone ? guessedZone.railsName : 'Eastern Time (US & Canada)';
};

const productFor = function (user) {
  // TODO: we might want to introduce this for all products but for now I'm only
  // using it to distinguish between client-side Scout and Feature Lab
  if (!user.isScout) { return; }

  return user.workspace?.isFeatureLab ? FeatureLab : Scout;
};

export default defineStore('currentUser', {
  state: () => {
    return {
      currentUser: {},
      currentProduct: {},
      timeZone: guessRailsZone(),
      ianaTimeZone: guessedIanaZone(),
      ssoEnabled: false // init as true since we use this within the logged-in state to restrict
    };
  },

  actions: {
    setCurrentUser(user) {
      this.currentUser = user;
      this.currentProduct = productFor(user);
      this.timeZone = user.timeZone || guessRailsZone();
      this.ianaTimeZone = TimeZoneHelper.railsToTzInfoMap[this.timeZone];
      this.ssoEnabled = user.ssoEnabled;
    },

    setTimeZone(tz) {
      this.timeZone = tz;
      this.ianaTimeZone = TimeZoneHelper.railsToTzInfoMap[this.timeZone];
    },

    refetchCurrentUser() {
      return User.query().then((updatedUser) => {
        this.setCurrentUser(updatedUser);
      });
    }
  }
});
