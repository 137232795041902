<template>
  <div>
    <div class="notification-header tiny-header-text">
      New {{ meetingType }} Times Suggested
    </div>

    <div class="notification-content">
      <div class="text-italic size-text-13px">
        <span class="semibold-weight">{{ suggesterName }}</span> has suggested new times for your {{ meetingType }}.<span v-if="showButtons"> If the proposed times do not suit your schedule, please suggest alternatives.</span>
      </div>

      <div class="top-20" v-if="showButtons">
        <button type="button" class="nv-button-blue smaller" @click="onConfirmAppointment(appointmentMetadata.id)">Respond To <span :class="{ 'hidden-xs': meetingType === 'video' }">{{ buttonText }}</span> Request</button>
      </div>
    </div>
  </div>
</template>

<script>
import Appointment from 'resources/marketplace/appointment.js';

export default {
  name: 'AlternativeTimeSuggestedNotification',

  props: {
    appointmentMetadata: {
      type:     Object,
      required: true
    },

    onConfirmAppointment: {
      type:    Function,
      required: true
    },

    showButtons: {
      type:    Boolean,
      default: false
    }
  },

  computed: {
    suggesterName() {
      return [this.appointmentMetadata.suggester.firstName, this.appointmentMetadata.suggester.lastName].join(' ');
    },

    appointmentResource() {
      return new Appointment(this.appointmentMetadata);
    },

    meetingType() {
      return this.appointmentResource.typeText();
    },

    buttonText() {
      return this.appointmentResource.buttonText();
    }
  }
};
</script>
