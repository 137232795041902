<template>
  <modal v-model="modalOpen" size="lg" :header="false" :footer="false" :backdrop="false" :keyboard="false" append-to-body>
    <modal-close-button :on-dismiss="dismissModal"></modal-close-button>

    <div class="appointment-modal">
      <div v-show="!submitted">
        <appointment-scheduling
          ref="scheduling"
          :appointment="appointment"
          :possible-participants="possibleParticipants"
          :selected-participants="selectedParticipants"
          :on-cancel-appointment="onCancelAppointment"
          :on-submit="submit">
        </appointment-scheduling>
      </div>

      <div v-show="submitted">
        <scheduling-confirmation
          :appointment="appointment"
          :selected-participants="selectedParticipants"
          :on-close-modal="closeModal">
        </scheduling-confirmation>
      </div>
    </div>
  </modal>
</template>

<script>
import ModalCloseButton from 'vue-app/shared/components/modals/modal-close-button.vue';
import AppointmentScheduling from 'vue-app/marketplace/shared/appointments/scheduling.vue';
import SchedulingConfirmation from 'vue-app/marketplace/shared/appointments/scheduling-confirmation.vue';
import modalToggle from 'vue-app/shared/mixins/modal-toggle.js';

export default {
  name: 'SchedulingModal',

  components: {
    ModalCloseButton,
    AppointmentScheduling,
    SchedulingConfirmation
  },

  mixins: [
    modalToggle
  ],

  props: {
    appointment: {
      type: Object,
      required: true
    },

    possibleParticipants: {
      type: Array,
      default: () => []
    },

    selectedParticipants: {
      type: Array,
      default: () => []
    },

    onCancelAppointment: {
      type: Function,
      required: false
    },

    onSubmit: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      submitted: false
    };
  },

  methods: {
    initModal(fullReset) {
      this.$refs.scheduling.reset(fullReset);
      this.submitted = false;
      this.openModal();
    },

    submit(availability, selectedParticipants) {
      return this.onSubmit(this.appointment, availability, selectedParticipants).then(() => {
        this.submitted = true;
      });
    }
  }
};
</script>
