<template>
  <div :class="['message clearfix', { 'received': isReceivedMessage, 'sent': isSentMessage }]">
    <div v-if="isReceivedMessage">
      <div class="message-photo">
        <profile-photo :user="sender" :smallest="true"></profile-photo>
      </div>
    </div>

    <div>
      <div :class="['size-text-11px dark-gray-text semibold-weight bottom-5', { 'text-left': isSentMessage, 'text-right': isReceivedMessage }]">
        {{ updatedAt }}
      </div>

      <div :class="['bubble', { 'left': isReceivedMessage, 'right': isSentMessage }]">
        <div class="simple-text" v-html="message.body"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfilePhoto from 'vue-app/shared/components/profile-photo.vue';
import DateFilter from 'vue-app/shared/lib/date-filter.js';

export default {
  name: 'Message',

  components: {
    ProfilePhoto
  },

  props: {
    conversation: {
      type: Object,
      required: true
    },

    receipt: {
      type: Object,
      required: true
    }
  },

  computed: {
    message() {
      return this.receipt.message;
    },

    isSentMessage() {
      return this.receipt.mailboxType === 'sentbox';
    },

    isReceivedMessage() {
      return !this.isSentMessage;
    },

    sender() {
      return this.conversation.participants.find(p => p.id === this.message.senderId && p.klass === this.message.senderType);
    },

    updatedAt() {
      return DateFilter.shortTime(this.message.updatedAt);
    }
  }
};
</script>
