import { defineStore } from 'pinia';

export default defineStore('appointmentWatcher', {
  state: () => ({
    // this is only being used as an event broadcast
    triggerValue: false
  }),

  actions: {
    triggerUpdate() {
      this.triggerValue = !this.triggerValue;
    }
  }
});
