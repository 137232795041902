<template>
  <svg viewBox="0 0 13 14" xmlns="http://www.w3.org/2000/svg"><defs><filter color-interpolation-filters="auto" id="download-icon-a"><feColorMatrix in="SourceGraphic" values="0 0 0 0 0.000000 0 0 0 0 0.411765 0 0 0 0 0.780392 0 0 0 1.000000 0" /></filter></defs><g transform="translate(-874 -429)" :filter="absoluteUrl('download-icon-a')" fill-rule="evenodd"><path d="m879.703 440.097-4.2-4.2a.35.35 0 0 1 .248-.597h2.45v-5.95a.35.35 0 0 1 .35-.35h2.8a.35.35 0 0 1 .35.35v5.95h2.45a.35.35 0 0 1 .247.597l-4.2 4.2a.35.35 0 0 1-.495 0zm5.854 1.503h-11.214c-.19 0-.343.15-.343.353v.694c0 .195.15.353.343.353h11.214c.19 0 .343-.15.343-.353v-.694a.346.346 0 0 0-.343-.353z" fill-rule="nonzero" /></g></svg>
</template>

<script>
export default {
  name: 'SvgDownload',

  methods: {
    absoluteUrl: function (id) {
      return `url(${window.location.href}#${id})`;
    }
  }
};
</script>
