<template>
  <div :class="['message clearfix', { 'received': isReceivedMessage, 'sent': isSentMessage }]">
    <div v-if="isReceivedMessage">
      <div class="message-photo">
        <profile-photo :user="sender" :smallest="true"></profile-photo>
      </div>
    </div>

    <div>
      <div :class="['size-text-11px dark-gray-text semibold-weight bottom-5', { 'text-left': isSentMessage, 'text-right': isReceivedMessage }]">
        {{ updatedAt }}
      </div>

      <div :class="['bubble pointer attachment', { 'left': isReceivedMessage, 'right': isSentMessage }]" @click="openAttachment">
        <div class="semibold-weight text-ellipsis">
          {{ attachment.name }}
        </div>

        <div>
          <svg-icon name="attachment" class="base-icon"></svg-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfilePhoto from 'vue-app/shared/components/profile-photo.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import DateFilter from 'vue-app/shared/lib/date-filter.js';

export default {
  name: 'Attachment',

  components: {
    ProfilePhoto,
    SvgIcon
  },

  props: {
    conversation: {
      type: Object,
      required: true
    },

    receipt: {
      type: Object,
      required: true
    },

    attachment: {
      type: Object,
      required: true
    }
  },

  computed: {
    message() {
      return this.receipt.message;
    },

    isSentMessage() {
      return this.receipt.mailboxType === 'sentbox';
    },

    isReceivedMessage() {
      return !this.isSentMessage;
    },

    sender() {
      return this.conversation.participants.find(p => p.id === this.message.senderId && p.klass === this.message.senderType);
    },

    updatedAt() {
      return DateFilter.shortTime(this.message.updatedAt);
    }
  },

  methods: {
    openAttachment() {
      window.open(this.attachment.url, '_blank');
    }
  }
};
</script>
