import BaseResource from 'resources/base-resource';
import { find, result } from 'lodash';

class Reference extends BaseResource {
  static baseUrl = '/lawyers/:lawyerId/references/:id.json';
  static urlTransformations = ['lawyerId', 'id'];

  hasQuote() {
    const quote = this.quote();
    return quote && quote.length;
  }

  quote() {
    // This assumes that Question 7 has the quote
    return this.responseToQuestion(7);
  }

  responseToQuestion(order) {
    return result(find(this.response.questions, { 'order': order }), 'answer');
  }
}

Reference.addGetCollectionRoute('approved');

export default Reference;
