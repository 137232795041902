<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 21"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.75" d="M16.545 7.91h4.318V3.59M10.5 5.319V10.5l4.318 2.591" /><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.75" d="M17.02 17.41a9.5 9.5 0 1 1 .197-13.628c1.271 1.287 2.32 2.5 3.646 4.128" /></svg>
</template>

<script>
export default {
  name: 'SvgClockDue'
};
</script>

<style scoped lang="scss">
  path {
    fill: none;
    stroke: #333;
  }
</style>
