<template>
  <div class="close-modal full-screen" @click="onDismiss('dismiss')">
    <svg-icon name="x3" class="base-icon"></svg-icon>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'ModalCloseButton',

  components: {
    SvgIcon
  },

  props: {
    onDismiss: {
      type: Function,
      required: true
    }
  }
};
</script>
