<template>
  <svg viewBox="0 0 54 54" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient x1="0%" y1="100%" x2="99.392%" y2=".608%" id="rg-a"><stop stop-color="#0A1370" offset="0%" /><stop stop-color="#9213b2" offset="99.945%" /></linearGradient><linearGradient x1="0%" y1="99.997%" x2="99.392%" y2=".611%" id="rg-b"><stop stop-color="#0A1370" offset="0%" /><stop stop-color="#9213b2" offset="99.945%" /></linearGradient></defs><g fill="none" fill-rule="evenodd"><circle :stroke="absoluteUrl('rg-a')" stroke-width="2" cx="27" cy="27" r="26" /><path d="M20.293 22.47l.047.002v1.84h-1.496v11.84h11.857V34.71h1.843v1.838A1.437 1.437 0 0131.069 38H18.523c-.718 0-1.312-.447-1.48-1.119a1.403 1.403 0 01-.041-.339C17 32.344 17 28.146 17 23.95c0-.682.462-1.272 1.126-1.43a1.64 1.64 0 01.362-.047c.602-.005 1.203-.002 1.805-.002zM31.202 18a1.53 1.53 0 011.146.474 975.043 975.043 0 004.157 4.161c.336.334.496.722.495 1.193-.004 2.745-.002 5.49-.002 8.235 0 .734-.481 1.315-1.201 1.447-.094.017-.192.02-.288.02H22.987c-.726 0-1.293-.455-1.446-1.165a1.407 1.407 0 01-.026-.294v-6.304c0-2.104-.005-4.208.001-6.312.002-.627.303-1.078.875-1.34a1.44 1.44 0 01.608-.114h5.906c.766 0 1.531.003 2.297-.001zm-1.78 1.848h-6.068v11.834h11.801v-6.087h-4.178c-.088 0-.177-.004-.264-.015a1.492 1.492 0 01-1.292-1.478v-4.254zm1.846.147v3.752h3.721l-3.721-3.752z" :fill="absoluteUrl('rg-b')" /></g></svg>
</template>

<script>
export default {
  name: 'SvgRequestGradient',

  methods: {
    absoluteUrl: function (id) {
      return `url(${window.location.href}#${id})`;
    }
  }
};
</script>
