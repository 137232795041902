class RatePackage {
  constructor(ratePackage) {
    this.rateType = ratePackage.rateType;
    this.type = ratePackage.type;
    this.dollars = ratePackage.dollars;
  }

  isHourly() {
    return this.rateType === 'hourly' || this.type === 'hourly';
  }

  isFlat() {
    return this.rateType === 'flat' || this.type === 'flat';
  }

  tag() {
    return this.isHourly() ? '/hr' : '';
  }
}

export default RatePackage;
