<template>
  <div>
    <div class="modal-header">
      <div>
        <div class="bottom-10">
          <participant-photos :participants="selectedParticipants"></participant-photos>
        </div>

        <div>
          Suggested meeting location
        </div>
      </div>
    </div>

    <div class="appointment-container">
      <form role="form" name="locationForm" class="a-form" novalidate>
        <div class="row top-10">
          <div class="col-md-8 col-md-offset-2">
            <div>
              <label for="location-title">Location Title</label>
              <input type="text" name="locationTitle" id="location-title" class="form-control bottom-20" placeholder="Location Title" v-model="location.title">
            </div>

            <div>
              <label for="location-address">Address</label>
              <input type="text" name="locationAddress" id="location-address" class="form-control bottom-10" placeholder="Address" v-model="location.address">
            </div>

            <div class="row tight-columns">
              <div class="col-sm-6">
                <label for="location-city">City</label>
                <input type="text" name="locationCity" id="location-city" class="form-control bottom-10" placeholder="City" v-model="location.city">
              </div>

              <div class="col-xs-6 col-sm-3">
                <label for="location-state">State</label>
                <input type="text" name="locationState" id="location-state" class="form-control bottom-10" placeholder="State" v-model="location.state">
              </div>

              <div class="col-xs-6 col-sm-3">
                <label for="location-zip">Zip Code</label>
                <input type="text" name="locationZip" id="location-zip" class="form-control bottom-20" placeholder="Zip Code" v-model="location.zip">
              </div>
            </div>

            <div>
              <label for="location-note">Additional Information</label>
              <textarea name="locationNote" id="location-note" class="form-control bottom-10" placeholder="(entering instructions, etc.)" rows="2" v-model="location.note"></textarea>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import ParticipantPhotos from 'vue-app/marketplace/shared/appointments/participant-photos.vue';

export default {
  name: 'StepLocation',

  components: {
    ParticipantPhotos
  },

  props: {
    appointment: {
      type: Object,
      required: true
    },

    selectedParticipants: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      location: this.appointment.appointmentLocationAttributes || {}
    };
  },

  methods: {
    reset() {
      this.location = this.appointment.appointmentLocationAttributes || {};
    }
  }
};
</script>
