import axios from 'axios';
import BaseResource from 'resources/base-resource';

import { find } from 'lodash';

class Attachment extends BaseResource {
  static baseUrl = '/mailboxer/attachments/:id.json';

  static upload(message, file) {
    const uploadStatus = find(message.uploading, function (upload) { return file.name === upload.name; });

    return this.save({ message_id: message.id, file: file.name }).then((resp) => {
      const attachment = { id: resp.id, name: file.name, url: null };

      const upload = axios({
        url: `/mailboxer/attachments/${attachment.id}`,
        method: 'PUT',
        data: { file: file },
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: () => {
          uploadStatus.progress = Math.min(100, parseInt(100.0 * event.loaded / event.total));
        }
      });

      upload.catch(() => {
        message.errors.push('Error uploading file: ' + file.name);
        Attachment.delete({ id: file.id, message_id: message.id });
      });

      return attachment;
    });
  }
}

export default Attachment;
