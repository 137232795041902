<template>
  <div>
    <div class="messaging-center-header-container">
      <div class="messaging-center-header">
        <div class="header-icon icon-left" @click="onHide">
          <svg-icon name="chat" class="base-icon"></svg-icon>
        </div>

        <div class="messaging-header-text left-icon text-center">
          Participants
        </div>
      </div>
    </div>

    <div class="messaging-center-content-container participants">
      <conversation-participant
        v-for="participant in participants"
        :key="`${participant.klass}-${participant.id}`"
        :participant="participant"
        :user-class="userClass"
        :shared-matters="sharedMatters">
      </conversation-participant>
    </div>

    <div class="messaging-center-action-container">
      <button type="button" class="nv-button-red a-button-size" @click="onArchiveConversation">Archive Conversation</button>
    </div>
  </div>
</template>

<script>
import ConversationParticipant from 'vue-app/mailboxer/marketplace/conversation-participant.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import Matter from 'resources/marketplace/matter.js';
import { orderBy } from 'lodash';

export default {
  name: 'ConversationParticipants',

  components: {
    ConversationParticipant,
    SvgIcon
  },

  props: {
    conversation: {
      type: Object,
      required: true
    },

    userClass: {
      type: String,
      required: true
    },

    onHide: {
      type: Function,
      required: true
    },

    onArchiveConversation: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      sharedMatters: [],
      matterAccessVerified: false
    };
  },

  computed: {
    participants() {
      return orderBy(this.conversation.participants, ['lastName', 'firstName']);
    },

    lawyerParticipants() {
      return this.conversation.participants.filter(p => p.klass === 'Lawyer');
    }
  },

  methods: {
    checkMatterAccess() {
      if (this.matterAccessVerified) { return; }

      if (this.userClass === 'client') {
        this.loadSharedMatters();
      }
      else {
        this.matterAccessVerified = true;
      }
    },

    loadSharedMatters() {
      let promises = [];

      LoadingService.loading('projects');

      this.lawyerParticipants.forEach(participant => {
        promises.push(
          Matter.queryData({ lawyer_id: participant.id }).then((data) => {
            this.sharedMatters.push({ participantId: participant.id, hasSharedMatters: data.metadata.hasSharedMatters });
          })
        );
      });

      Promise.all(promises).finally(() => {
        this.matterAccessVerified = true;
        LoadingService.done('projects');
      });
    }
  }
};
</script>
