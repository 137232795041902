<template>
  <div>
    <div class="modal-header">
      <div>
        <div class="bottom-10">
          <participant-photos :participants="selectedParticipants"></participant-photos>
        </div>

        <div>
          Preferred call/meeting format
        </div>
      </div>
    </div>

    <div class="appointment-container">
      <div class="row bottom-20">
        <div class="col-md-4 top-20" v-for="type in types">
          <button type="button" :class="['radio-select-button', { 'selected': isTypeSelected(type) }]" @click="selectType(type)"><span class="radio-select"></span>{{ typeLabel(type) }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ParticipantPhotos from 'vue-app/marketplace/shared/appointments/participant-photos.vue';

export default {
  name: 'StepAppointmentType',

  components: {
    ParticipantPhotos
  },

  props: {
    appointment: {
      type: Object,
      required: true
    },

    selectedParticipants: {
      type: Array,
      default: () => []
    },

    onTypeSelected: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      types: ['phone', 'video', 'in-person'],
      typeMap: {
        'phone': 'Conference Call (Audio)',
        'video': 'Conference Call (Video)',
        'in-person': 'In-Person Meeting'
      },
      selectedType: this.appointment.type
    };
  },

  methods: {
    reset() {
      this.selectedType = this.appointment.type;
    },

    isTypeSelected(type) {
      return this.selectedType === type;
    },

    typeLabel(type) {
      return this.typeMap[type];
    },

    selectType(type) {
      this.selectedType = type;
      this.onTypeSelected(type);
    }
  }
};
</script>
