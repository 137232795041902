<template>
  <div>
    <div class="messaging-center-header-container contacts">
      <div class="messaging-center-header">
        <div class="messaging-header-text">
          New Message
        </div>

        <button type="button" class="pseudo-link-blue header-icon size-text-13px text-uppercase dark-gray-text bold-weight" @click="onCancel">Cancel</button>
      </div>

      <div class="add-contact-form">
        <div class="row">
          <div class="col-xs-8">
            <div class="expandable-input">
              <div class="tag-list" v-if="newConversation.recipients">
                <div class="tag-list-item compact gray" v-for="contact in newConversation.recipients" :key="`${contact.klass}-${contact.id}`">
                  <div class="right-5 tag-list-item-content text-ellipsis">
                    {{ contact.fullName }}
                  </div>

                  <div class="tag-list-item-delete">
                    <a href="" @click.prevent="removeContact(contact)"><svg-icon name="x3" class="base-icon smallest"></svg-icon></a>
                  </div>
                </div>
              </div>

              <textarea id="recipient" :placeholder="newConversation.recipients.length ? '' : 'Find or start a message...'" rows="1" v-model="recipientFilterKeyword" @focus="onInputFocus" @blur="onInputBlur"></textarea>
            </div>
          </div>

          <div class="col-xs-4">
            <button type="button" class="nv-button-blue" :disabled="!newConversation.recipients.length" @click="startConversation">Go</button>
          </div>
        </div>
      </div>
    </div>

    <div class="messaging-center-content-header tiny-header-text">
      Your Contacts
    </div>

    <div class="messaging-center-content-container">
      <div v-for="(contact, index) in filteredContacts" :key="`${contact.klass}-${contact.id}`" :class="['messaging-center-list-item pointer contact', { 'bottom-30': index === filteredContacts.length - 1 }]" @click="addContact(contact)">
        <div class="item-photo">
          <profile-photo :user="contact" :small="true"></profile-photo>
        </div>

        <div class="item-content size-text-13px semibold-weight">
          <div class="content-left text-ellipsis">
            {{ contact.fullName }}
          </div>

          <div class="content-right">
            <span class="text-uppercase bold-weight add-link"><a href="" @click.prevent>ADD</a></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import ProfilePhoto from 'vue-app/shared/components/profile-photo.vue';
import User from 'resources/user.js';
import { orderBy } from 'lodash';

export default {
  name: 'ContactList',

  components: {
    SvgIcon,
    ProfilePhoto
  },

  props: {
    onStartConversation: {
      type: Function,
      required: true
    },

    onCancel: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      recipientFilterKeyword: '',
      newConversation: { recipients: [] },
      allowedRecipients: []
    };
  },

  computed: {
    filteredContacts() {
      return this.allowedRecipients.filter((contact) => {
        return contact.klass !== 'PotentialClient' &&
          contact.fullName.match(new RegExp(this.recipientFilterKeyword, 'gi')) &&
          !this.newConversation.recipients.find(r => r.id === contact.id && r.klass === contact.klass);
      });
    }
  },

  mounted() {
    this.newConversation = { recipients: [] };

    User.allowedMessageRecipients().then((res) => {
      this.allowedRecipients = orderBy(res.data, ['lastName'], ['asc']);
      this.setContentHeight();
    });
  },

  methods: {
    setContentHeight() {
      const totalHeight = document.querySelector('.messaging-center').getBoundingClientRect().height;
      const headerHeight = document.querySelector('.messaging-center-header-container').getBoundingClientRect().height;
      const actionBarHeight = document.querySelector('.messaging-action-bar').getBoundingClientRect().height;
      const contentHeaderHeight = document.querySelector('.messaging-center-content-header').getBoundingClientRect().height;
      const contentHeight = totalHeight - headerHeight - actionBarHeight - contentHeaderHeight;

      document.querySelector('.messaging-center-content-container').style.height = `${contentHeight}px`;
    },

    onInputFocus() {
      document.querySelector('.expandable-input').classList.add('focused');
    },

    onInputBlur() {
      document.querySelector('.expandable-input').classList.remove('focused');
    },

    startConversation() {
      this.onStartConversation(this.newConversation);
    },

    addContact(contact) {
      this.recipientFilterKeyword = '';
      this.newConversation.recipients.push(contact);

      document.getElementById('recipient').focus();
      this.$nextTick(this.setContentHeight);
    },

    removeContact(contact) {
      this.newConversation.recipients = this.newConversation.recipients.filter(r => !(r.id === contact.id && r.klass === contact.klass));

      document.getElementById('recipient').focus();
      this.$nextTick(this.setContentHeight);
    }
  }
};
</script>
