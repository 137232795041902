import { camelCase, each, has, isArray, isDate, isObject, isString } from 'lodash';

// TODO: see if we can replace with axios-case-converter
const snakeToCamelCaseTransformer = function (data) {
  let transformedData;

  const isDateKey = (key) => {
    return !!key.match(/\d{4}-\d{2}-\d{2}/);
  };

  if (isArray(data)) {
    transformedData = [];

    each(data, (value) => {
      transformedData.push(snakeToCamelCaseTransformer(value));
    });
  }
  else if (has(data, 'data') && isArray(data.data)) {
    transformedData = [];

    each(data.data, (value) => {
      transformedData.push(snakeToCamelCaseTransformer(value));
    });
  }
  else if (isObject(data)) {
    transformedData = {};

    if (has(data, 'data.attributes')) {
      data = data.data.attributes;
    }
    else if (has(data, 'attributes')) {
      data = data.attributes;
    }

    if (has(data, 'attributes')) {
      data = data.attributes;
    }

    if (has(data, 'data')) {
      data = data.data;
    }

    if (isArray(data)) {
      transformedData = [];

      each(data, (value) => {
        transformedData.push(snakeToCamelCaseTransformer(value));
      });
    }
    else {
      each(data, (value, key) => {
        if (key === 'proficiencies' && !isArray(value)) {
          transformedData[key] = value;
        }
        else if (isDateKey(key)) {
          transformedData[key] = snakeToCamelCaseTransformer(value);
        }
        else if (isObject(value)) {
          transformedData[camelCase(key)] = snakeToCamelCaseTransformer(value);
        }
        else {
          transformedData[camelCase(key)] = value;
        }
      });
    }
  }
  else {
    transformedData = data;
  }

  return transformedData;
};

const camelToSnakeCaseTransformer = function (data) {
  let transformedData;

  const underscore = (key) => {
    if (!isString(key)) { return key; }

    return key.replace(/[A-Z\d]/g, (match, index) => {
      return index === 0 ? match : '_' + match.toLowerCase();
    });
  };

  const isFile = (file) => {
    return file != null &&
      (file instanceof window.Blob || (file.flashId && file.name && file.size));
  };

  const isDateKey = (key) => {
    return !!key.match(/\d{4}-\d{2}-\d{2}/);
  };

  if (isArray(data)) {
    transformedData = [];

    each(data, (value) => {
      transformedData.push(camelToSnakeCaseTransformer(value));
    });
  }
  else if (isObject(data)) {
    transformedData = {};

    each(data, (value, key) => {
      if (key === 'errors' || key.charAt(0) === '$') { return; }

      if (isDateKey(key) || (key === 'proficiencies' && !isArray(value))) {
        transformedData[key] = value;
      }
      else if (isDate(value)) {
        transformedData[underscore(key)] = value.toJSON();
      }
      else if (isObject(value) && !isFile(value)) {
        transformedData[underscore(key)] = camelToSnakeCaseTransformer(value);
      }
      else {
        transformedData[underscore(key)] = value;
      }
    });
  }
  else {
    transformedData = data;
  }

  return transformedData;
};

export default {
  snakeToCamelCaseTransformer: snakeToCamelCaseTransformer,
  camelToSnakeCaseTransformer: camelToSnakeCaseTransformer
};
