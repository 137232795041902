<template>
  <svg viewBox="0 0 52 52" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient x1="28.595%" y1="108.052%" x2="76.244%" y2="4.906%" id="addinv-a"><stop stop-color="#1484C9" offset="0%" /><stop stop-color="#AF2E8D" offset="100%" /></linearGradient><linearGradient x1="15.319%" y1="80.599%" x2="76.346%" y2="19.401%" id="addinv-b"><stop stop-color="#1484C9" offset="0%" /><stop stop-color="#AF2E8D" offset="100%" /></linearGradient></defs><g fill="none" fill-rule="evenodd"><path d="M25 50c13.807 0 25-11.193 25-25S38.807 0 25 0 0 11.193 0 25s11.193 25 25 25z" :stroke="absoluteUrl('addinv-a')" stroke-dasharray="3" transform="translate(1 1)" /><path d="M10.865 10.16c3.23 0 5.543 1.48 6.627 2.355a.82.82 0 01.126 1.148l-.076.082a.802.802 0 01-1.054.045c-.892-.714-2.883-2.005-5.623-2.005s-4.73 1.291-5.623 2.006a.802.802 0 01-.964.03l-.092-.076a.819.819 0 01.051-1.229c1.084-.875 3.397-2.355 6.628-2.355zM3.043 5.077c.445 0 .808.364.808.812v1.305h1.312a.8.8 0 01.795.812l-.006.102a.797.797 0 01-.79.71h-1.31v1.304a.811.811 0 01-.81.813.811.811 0 01-.808-.813V8.817H.964c-.446 0-.788-.363-.788-.812l.006-.102c.047-.4.37-.71.781-.71h1.271V5.888c0-.448.364-.812.809-.812zM10.865.183c2.458 0 4.457 2.016 4.457 4.493 0 2.478-2 4.494-4.457 4.494-2.458 0-4.458-2.016-4.458-4.494 0-2.477 2-4.493 4.458-4.493zm0 1.624c-1.57 0-2.847 1.287-2.847 2.87 0 1.581 1.277 2.868 2.847 2.868s2.846-1.287 2.846-2.869c0-1.582-1.277-2.869-2.846-2.869z" transform="translate(17 19)" :fill="absoluteUrl('addinv-b')" /></g></svg>
</template>

<script>
export default {
  name: 'SvgAddInvitee',

  methods: {
    absoluteUrl: function (id) {
      return `url(${window.location.href}#${id})`;
    }
  }
};
</script>
