const config = {
  plaidEnv: process.env.PLAID_ENV || 'sandbox',
  plaidPublicKey: process.env.PLAID_PUBLIC_KEY,
  recaptchaSiteKey: process.env.RECAPTCHA_SITE_KEY,
  twilioPhoneText: process.env.TWILIO_PHONE_TEXT,
  saleforceBaseURL: process.env.SALESFORCE_BASE_URL,
  defaultManagementFeePercent: 25.0
};

export default config;
