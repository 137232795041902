<template>
  <vue-router-link v-if="usesVueRouter" :to="href"><slot></slot></vue-router-link>
  <a v-else :href="href"><slot></slot></a>
</template>

<script>
// This component is a stand-in for the component that comes with Vue Router.
// Since it doesn't work to incorporate that version in our hybrid Angular/Vue
// code, I created this to use in the hybrid apps until the full Vue transition.
// The syntax should allow a direct transition to the Vue Router version.
import { RouterLink } from 'vue-router';

export default {
  name: 'RouterLink',

  components: {
    VueRouterLink: RouterLink
  },

  props: {
    to: {
      type: String,
      required: true
    }
  },

  computed: {
    base() {
      return window.location.pathname.split('/')[1];
    },

    usesVueRouter() {
      return this.base === 'client-app';
    },

    href() {
      if (this.usesVueRouter) {
        return this.to;
      }
      else {
        return `/${this.base}/${this.to}`.replaceAll('//', '/');
      }
    }
  }
};
</script>
