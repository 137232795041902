import axios from 'axios';
import BaseResource from 'resources/base-resource';

class TwoFactorAuthenticatable extends BaseResource {
  static baseUrl = '/two_factor_authenticatable.json';

  static otpBackupCodes(data) {
    return axios.post('/two_factor_authenticatable/otp_backup_codes.json', data);
  }

  static signInWithTwoFactor(data) {
    return axios.post('/two_factor_authenticatable/sign_in_with_two_factor.json', data);
  }

  static sendOneTimePassword(data) {
    return axios.post('/two_factor_authenticatable/otp_via_email.json', data);
  }

  static verifyOtp(data) {
    return axios.post('/two_factor_authenticatable/verify_otp.json', data);
  }

  static regenerateBackupCodes() {
    return axios.post('/two_factor_authenticatable/regenerate_backup_codes.json');
  }

  static passwordVerification(data) {
    return axios.post('/two_factor_authenticatable/password_verification.json', data);
  }
}

export default TwoFactorAuthenticatable;
