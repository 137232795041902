<template>
  <div class="loading-wrapper">
    <div v-show="isLoading" class="text-center loading-modal">
      <div class="throbber">
        Loading...
      </div>
    </div>

    <div v-show="!isLoading" v-if="!renderAfterLoading">
      <slot></slot>
    </div>

    <div v-if="renderAfterLoading && !isLoading">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import LoadingService from 'vue-app/shared/services/loading-service';

export default {
  name: 'LoadingSection',

  props: {
    name: {
      type: String,
      required: true
    },

    renderAfterLoading: {
      type:    Boolean,
      default: false
    }
  },

  computed: {
    isLoading() {
      return LoadingService.isLoading(this.name);
    }
  }
};
</script>

<style lang="scss" scoped>
  .padded .loading-modal {
    padding: 15px 0;
  }
</style>
