class PollingService {
  constructor() {
    this.polls = {};
  }

  startPolling(name, func, interval = 300000) {
    func();
    this.polls[name] = setInterval(func, interval);
  }

  stopPolling(name) {
    clearInterval(this.polls[name]);
    delete this.polls[name];
  }
}

export default new PollingService();
