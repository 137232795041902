<template>
  <validation-provider :rules="rules" v-slot="{ errors }" :mode="passiveAggressive">
    <label v-if="label" :for="id" :class="{ 'disabled-text': disableInput }">{{ label }} <span class="inline-help required" v-if="required">*</span></label>
    <slot name="tag"></slot>
    <slot name="description"></slot>

    <div>
      <textarea :id="id" :data-testid="id" :name="name" :class="['form-control', { 'has-error': errors.length }]" @input="onInput($event.target.value)" :value="$attrs.value" :rows="rows" :maxlength="maxlength" @keydown="onKeyDown" @focus="resize" ref="textarea" v-if="hasLimit"></textarea>

      <textarea :id="id" :data-testid="id" :name="name" :class="['form-control', { 'has-error': errors.length, 'disabled-text': disableInput }]" @input="onInput($event.target.value)" :value="$attrs.value" :rows="rows" :disabled="disableInput" @focus="resize" ref="textarea" v-else></textarea>

      <div class="error-text top-5" v-if="errors.length">
        {{ errors[0] }}
      </div>
    </div>

    <div v-if="showLimitMessage" class="top-10 error-text">
      <slot name="limit-message" :maxlength="maxlength" :input-length="inputLength">
      </slot>
    </div>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import interactionModes from 'vue-app/shared/mixins/interaction-modes';

export default {
  name: 'TextareaVertical',

  components: {
    ValidationProvider
  },

  mixins: [
    interactionModes
  ],

  props: {
    label: {
      type: String,
      required: false
    },

    name: {
      type: String,
      required: false
    },

    id: {
      type: String,
      required: false
    },

    autoresize: {
      type: Boolean,
      default: false
    },

    maxlength: {
      type: Number,
      default: 0
    },

    rules: {
      type: String,
      default: null
    },

    rows: {
      type: Number,
      default: 3
    },

    withLimit: {
      type: Boolean,
      default: true
    },

    defaultValue: {
      type: String,
      default: ''
    },

    disableInput: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      currentValue: ''
    };
  },

  computed: {
    required() {
      return this.rules?.includes('required');
    },

    hasLimit() {
      return this.maxlength > 0 && this.withLimit;
    },

    showLimitMessage() {
      return this.inputLength > this.maxlength;
    },

    inputLength() {
      if (this.currentValue.length) {
        return this.currentValue.length;
      }
      else {
        return this.defaultValue.length;
      }
    }
  },

  methods: {
    onInput(value) {
      if (this.autoresize) { this.resize(); }

      this.currentValue = value;
      this.$emit('input', value);
    },

    resize() {
      this.$refs.textarea.style.height = 'auto';
      // NOTE: scrollHeight does not include borders, so add 2px
      this.$refs.textarea.style.height = `${this.$refs.textarea.scrollHeight + 2}px`;
    },

    onKeyDown(evt) {
      if (evt.target.value.length >= this.maxlength) {
        if (this.keyPressNumberOrLetter(evt.keyCode)) {
          evt.preventDefault();

          return;
        }
      }
    },

    keyPressNumberOrLetter(keyCode) {
      return keyCode >= 48 && keyCode <= 90;
    }
  }
};
</script>

<style lang="scss" scoped>
  textarea {
    overflow: hidden;
    height: auto;
  }
</style>
