import axios from 'axios';
import BaseResource from 'resources/base-resource';
import Review from 'resources/marketplace/review.js';
import Reference from 'resources/marketplace/reference.js';
import DateFilter from 'vue-app/shared/lib/date-filter';
import { startCase } from 'lodash';

class Lawyer extends BaseResource {
  static baseUrl = '/lawyers/:id.json';

  static rehire(id, data) {
    const url = `${this.baseUrl.replace(':id.json', id)}/rehire.json`;
    return axios.post(url, data);
  }

  isApplying() {
    return this.status === 'applying';
  }

  isApplicationComplete() {
    return this.status === 'application_complete';
  }

  isBaseProfile() {
    return this.status === 'base_profile';
  }

  isApproved() {
    return this.status === 'approved';
  }

  isOnHold() {
    return this.status === 'on_hold';
  }

  isRejected() {
    return this.status === 'rejected';
  }

  displayStatus() {
    return startCase(this.status);
  }

  firstInitialLastName() {
    return startCase(this.firstName[0]) + '. ' + startCase(this.lastName);
  }

  isFirmAdmin() {
    return this.accountType === 'admin';
  }

  accountStatus() {
    if (!this.isApproved()) {
      return startCase(this.status);
    }

    if (this.available || (this.availability && this.availability.status)) {
      return 'Available';
    }

    if (!this.available) {
      return 'Indefinitely Unavailable';
    }

    if (!this.availability) {
      return 'Currently Unavailable';
    }

    if (this.availability.currentUnavailability.startDate && this.availability.currentUnavailability.endDate) {
      return 'Unavailable (' + DateFilter.filterDate(this.availability.currentUnavailability.startDate) + ' - ' + DateFilter.filterDate(this.availability.currentUnavailability.endDate) + ')';
    }

    if (this.availability.currentUnavailability.startDate) {
      return 'Indefinitely Unavailable (' + DateFilter.filterDate(this.availability.currentUnavailability.startDate) + ')';
    }

    return 'Indefinitely Unavailable';
  }

  loadReviews() {
    this.reviews = [];
    const lawyer = new this.constructor(this);

    const reviewPromise = Review.query({ lawyer_id: lawyer.id }).then((reviews) => {
      return reviews.filter(review => review.hasQuote());
    });

    const referencePromise = Reference.approved({ lawyerId: lawyer.id }).then((references) => {
      return references.filter(reference => reference.hasQuote());
    });

    return Promise.all([reviewPromise, referencePromise]).then(([reviewsWithQuotes, referencesWithQuotes]) => {
      lawyer.reviews = reviewsWithQuotes.concat(referencesWithQuotes);
      return lawyer;
    });
  }
}

Lawyer.addPutRoute('updatePassword', '/password');

export default Lawyer;
