<template>
  <span v-if="name" class="user-pic-container">
    <span :class="['missing-photo text-uppercase', missingPhotoClass]">{{ nameInitials }}</span>
  </span>

  <span v-else :class="['user-pic-container', { 'editing': showEdit }]" @mouseenter="toggleEditOn()" @mouseleave="toggleEditOff()" @click="$emit('click')">
    <span class="edit-text" v-if="showEdit">Edit</span>

    <img v-if="hasPhoto" :src="userPhoto" alt="Photo" :class="['user-pic', photoClass]">
    <span v-else :class="['missing-photo', missingPhotoClass]">{{ showEdit ? '&nbsp;' : userInitials }}</span>
  </span>
</template>

<script>
import { keys, pickBy } from 'lodash';

export default {
  name: 'ProfilePhoto',

  props: {
    user: {
      type: Object,
      default: () => ({})
    },
    name: String,
    smallest: Boolean,
    small: Boolean,
    medium: Boolean,
    larger: Boolean,
    editable: Boolean
  },

  data() {
    return {
      showEdit: false
    };
  },

  computed: {
    userInitials() {
      if (this.user.firstName && this.user.lastName) {
        return (this.user.firstName[0] + this.user.lastName[0]).toUpperCase();
      }
      else {
        return '@';
      }
    },

    nameInitials() {
      return this.name[0].toUpperCase();
    },

    userPhoto() {
      return this.user.photoLarge || this.user.photoMedium;
    },

    hasPhoto() {
      return !!this.userPhoto;
    },

    missingPhotoClass() {
      const classMap = {
        'smallest': this.smallest,
        'small': this.small,
        'medium': this.medium,
        'larger': this.larger
      };

      return keys(pickBy(classMap))[0];
    },

    photoClass() {
      const classMap = {
        'smallest-pic': this.smallest,
        'small-pic': this.small,
        'medium-pic': this.medium,
        'larger-pic': this.larger
      };

      return keys(pickBy(classMap))[0];
    }
  },

  methods: {
    toggleEditOn() {
      if (this.editable) {
        this.showEdit = true;
      }
    },

    toggleEditOff() {
      if (this.editable) {
        this.showEdit = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
  @import "stylesheets/globals/variables";

  .missing-photo {
    box-shadow: 0 0 8px 0 rgba($black, .1);
    display: inline-block;
    width: 60px;
    height: 60px;
    padding: 23px 0;
    border-radius: 50%;
    background-color: $bg-color;
    font-size: 13px;
    font-weight: 700;
    line-height: 14px;
    text-align: center;
    cursor: default;
    user-select: none;

    &.larger {
      font-size: 18px;
      font-weight: 600;
      height: 80px;
      line-height: 20px;
      padding: 29px 0;
      width: 80px;
    }

    &.medium {
      width: 50px;
      height: 50px;
      padding: 19px 0;
      font-size: 12px;
      line-height: 12px;
    }

    &.small {
      width: 40px;
      height: 40px;
      padding: 14px 0;
      font-size: 11px;
      line-height: 12px;
    }

    &.smallest {
      width: 30px;
      height: 30px;
      padding: 9px 0;
      font-size: 8px;
      line-height: 11px;
    }
  }
</style>
