import BaseResource from 'resources/base-resource';
import axios from 'axios';
import { compact, join } from 'lodash';

class Workspace extends BaseResource {
  static baseUrl = '/workspaces/:id.json';

  static mergeClient(id, data) {
    const url = `${this.baseUrl.replace(':id.json', id)}/merge_client.json`;
    return axios.post(url, data);
  }

  location() {
    return join(compact([this.city, this.state, this.country]), ', ') || '—';
  }

  // TODO: not fully migrated
  // uploadLogo(file) {
  //   return Upload.upload({
  //     url: '/workspaces/' + this.id,
  //     method: 'PUT',
  //     data: { workspace: { logo: file } }
  //   });
  // }
}

export default Workspace;
