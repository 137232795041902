<template>
  <modal v-model="modalOpen" size="md-sm" :header="false" :footer="false" append-to-body>
    <modal-close-button :on-dismiss="dismissModal"></modal-close-button>

    <div class="notice-modal">
      <div class="notice-header">
        Send Files
      </div>

      <div class="notice-regular-text">
        <loading-section name="uploads">
          <div class="red-panel" v-if="errors.length">
            <span class="red-text size-text-12px">{{ errors.join('; ') }}</span>
          </div>

          <div class="message-upload-container">
            <div class="message-upload" v-for="(attachment, index) in attachments">
              <div class="message-upload-inner">
                <div class="text-ellipsis semibold-weight">
                  <svg-icon name="attachment" class="base-icon right-5"></svg-icon><span class="semibold-weight">{{ attachment.name }}</span>
                </div>

                <div @click="removeFile(index)">
                  <svg-icon name="x3" class="base-icon smallest less-margin"></svg-icon>
                </div>
              </div>
            </div>
          </div>

          <div :class="['expandable-input bottom-20', { 'focused': isMessageFocused }]">
            <textarea id="send-message-reply" autofocus placeholder="Enter message..." v-model="message.body" @focus="isMessageFocused = true" @blur="isMessageFocused = false"></textarea>
          </div>

          <div class="row tight-columns top-20">
            <div class="col-xs-2">
              <file-selector
                ref="fileSelector"
                :multiple="true"
                accept=".jpg,.jpeg,.gif,.png,.bmp,.pdf,.doc,.docx,.txt,.odt,.rtf"
                :max-size="5"
                @change="addFiles">
                <div class="nv-button-white smaller flex-center vertical-center">
                  <svg-icon name="attachment" class="base-icon smaller"></svg-icon>
                </div>
              </file-selector>
            </div>

            <div class="col-xs-10">
              <button type="button" class="nv-button-blue smaller" :disabled="!files.length" @click="sendFiles">Send File(s)</button>
            </div>
          </div>
        </loading-section>
      </div>
    </div>
  </modal>
</template>

<script>
import ModalCloseButton from 'vue-app/shared/components/modals/modal-close-button.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import FileSelector from 'vue-app/shared/components/file-selector.vue';
import Message from 'resources/marketplace/message.js';
import modalToggle from 'vue-app/shared/mixins/modal-toggle.js';
import LoadingService from 'vue-app/shared/services/loading-service.js';

export default {
  name: 'AttachmentsModal',

  components: {
    ModalCloseButton,
    LoadingSection,
    SvgIcon,
    FileSelector
  },

  mixins: [
    modalToggle
  ],

  props: {
    conversation: {
      type: Object,
      required: true
    },

    files: {
      type: Array,
      default: () => []
    }
  },

  emits: ['onSuccess'],

  data() {
    return {
      attachments: [],
      errors: [],
      isMessageFocused: false,
      message: { draft: false, 'attachments_attributes': [] }
    };
  },

  watch: {
    files() {
      this.attachments = [...this.files];
    }
  },

  methods: {
    resetAndOpenModal() {
      this.errors = [];
      this.message = { draft: false, body: null, 'attachments_attributes': [] };
      this.openModal();
    },

    addFiles(files) {
      Array.from(files).forEach(file => this.attachments.push(file));
      this.$refs.fileSelector.reset();
    },

    removeFile(index) {
      this.attachments.splice(index, 1);
      if (!this.attachments.length) { this.closeModal(); }
    },

    sendFiles() {
      if (!this.attachments.length) { return; }

      LoadingService.loading('uploads');
      this.attachments.forEach(file => this.message['attachments_attributes'].push({ file: file }));

      Message.postForm({
        conversation_id: this.conversation.id,
        sending_files: true,
        recipients: this.conversation.formattedRecipients,
        message: this.message
      })
      .then(() => {
        this.$emit('on-success');
        this.closeModal();
      })
      .catch((response) => {
        this.errors = response.response.data.errors;
      })
      .finally(() => {
        LoadingService.done('uploads');
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  :deep(.file-select) {
    width: 100%;
    cursor: pointer;
  }
</style>
