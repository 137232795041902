<template>
  <form role="form" class="a-form" novalidate>
    <select id="time-zone" name="timeZoneSelect" class="form-control" v-model="userTimeZone" @change="updateTimeZone">
      <option v-for="zoneName in railsTimeZoneNames" :value="zoneName">{{ zoneName }}</option>
    </select>
  </form>
</template>

<script>
import Client from 'resources/client.js';
import Lawyer from 'resources/lawyer.js';
import TimeZoneHelper from 'vue-app/shared/services/time-zone-helper.js';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState, mapActions } from 'pinia';

export default {
  name: 'TimeZoneEditor',

  data() {
    return {
      userTimeZone: null
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { user: 'currentUser', timeZone: 'timeZone' }),

    railsTimeZoneNames() {
      return TimeZoneHelper.sortedByRailsName.map(zone => zone.railsName);
    }
  },

  beforeMount() {
    // data from pinia not available until now
    this.userTimeZone = this.timeZone;
  },

  methods: {
    ...mapActions(useCurrentUserStore, ['setTimeZone']),

    updateTimeZone() {
      if (this.timeZone === this.userTimeZone) { return; }
      LoadingService.loading('times');

      let updatePromise = Promise.resolve();

      if (this.user.klass === 'Client') {
        updatePromise = Client.update({ id: this.user.id, client: { 'time_zone': this.userTimeZone } });
      }
      else if (this.user.klass === 'Lawyer') {
        updatePromise = Lawyer.update({ id: this.user.id, lawyer: { 'time_zone': this.userTimeZone } });
      }

      updatePromise.then(() => {
        this.setTimeZone(this.userTimeZone);
        LoadingService.done('times');
      });
    }
  }
};
</script>
