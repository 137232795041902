<template>
  <div>
    <div class="conversation-viewer" v-show="!viewingParticipants">
      <conversation-header
        :conversation="conversation"
        :on-deselect="deselectConversation"
        :on-view-participants="toggleParticipantsView">
      </conversation-header>

      <conversation-messages
        :conversation="conversation"
        :on-confirm-appointment="startConfirmingAppt"
        :on-update-availability="startUpdatingAppt"
        :on-cancel-appointment="startCancellingAppt"
        :on-reschedule-appointment="startReschedulingAppt"
        :on-request-proposal="requestProposal">
      </conversation-messages>

      <conversation-actions
        :conversation="conversation"
        :on-toggle-expanded="scrollToLastMessage"
        :on-schedule-call="startSchedulingAppt"
        :on-save-draft="saveDraftMessage"
        :on-attachments-selected="openAttachmentsModal"
        :on-send="sendMessage">
      </conversation-actions>
    </div>

    <div v-show="viewingParticipants">
      <conversation-participants
        ref="conversationParticipants"
        :conversation="conversation"
        :user-class="userClass"
        :on-hide="toggleParticipantsView"
        :on-archive-conversation="onArchiveConversation">
      </conversation-participants>
    </div>

    <appointment-modals
      ref="appointmentModals"
      @on-success="onRefresh">
    </appointment-modals>

    <attachments-modal
      ref="attachmentsModal"
      :conversation="conversation"
      :files="attachments"
      @on-success="onRefresh">
    </attachments-modal>
  </div>
</template>

<script>
import ConversationHeader from 'vue-app/mailboxer/marketplace/conversation-header.vue';
import ConversationMessages from 'vue-app/mailboxer/marketplace/conversation-messages.vue';
import ConversationActions from 'vue-app/mailboxer/marketplace/conversation-actions.vue';
import ConversationParticipants from 'vue-app/mailboxer/marketplace/conversation-participants.vue';
import AppointmentModals from 'vue-app/mailboxer/marketplace/appointment-modals.vue';
import AttachmentsModal from 'vue-app/mailboxer/marketplace/attachments-modal.vue';
import PossibleLawyer from 'resources/marketplace/possible-lawyer.js';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import useAppointmentWatcherStore from 'vue-app/stores/marketplace/appointment-watcher.js';
import { mapState, mapActions } from 'pinia';

export default {
  name: 'ConversationViewer',

  components: {
    ConversationHeader,
    ConversationMessages,
    ConversationActions,
    ConversationParticipants,
    AppointmentModals,
    AttachmentsModal
  },

  props: {
    conversation: {
      type: Object,
      required: true
    },

    userClass: {
      type: String,
      required: true
    },

    onCreateOrUpdateMessage: {
      type: Function,
      required: true
    },

    onDeselect: {
      type: Function,
      required: true
    },

    onRefresh: {
      type: Function,
      required: true
    },

    onArchiveConversation: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      isSaving: false,
      savingPromise: Promise.resolve(),
      viewingParticipants: false,
      attachments: []
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { user: 'currentUser' }),

    otherApptParticipants() {
      return this.appointment?.participantsOtherThan(this.user)?.map(ap => ap.participant) || [];
    }
  },

  methods: {
    ...mapActions(useAppointmentWatcherStore, { broadcastAppointmentsChanged: 'triggerUpdate' }),

    scrollToLastMessage(smooth) {
      const container = document.getElementById('conversation-messages');

      this.$nextTick(() => {
        if (smooth) {
          container.scrollTo({ top: container.scrollHeight, behavior: 'smooth' });
        }
        else {
          container.scrollTop = container.scrollHeight;
        }
      });
    },

    toggleParticipantsView() {
      if (!this.viewingParticipants) {
        this.$refs.conversationParticipants.checkMatterAccess();
        this.viewingParticipants = true;
      }
      else {
        this.viewingParticipants = false;
      }
    },

    deselectConversation() {
      this.saveDraftMessage();
      this.savingPromise.then(() => { this.onDeselect(); });
    },

    saveDraftMessage() {
      if (this.isSaving) { return; }

      this.isSaving = true;
      this.savingPromise = this.onCreateOrUpdateMessage(false).then(() => {
        this.isSaving = false;
      });
    },

    openAttachmentsModal(files) {
      if (!files || !files.length) { return; }

      this.attachments = Array.from(files);
      this.$refs.attachmentsModal.resetAndOpenModal();
    },

    startSchedulingAppt() {
      const participants = this.conversation.participants;
      this.$refs.appointmentModals.startSchedulingAppt(participants, participants);
    },

    startConfirmingAppt(appointmentId) {
      this.$refs.appointmentModals.startConfirmingAppt(appointmentId);
    },

    startUpdatingAppt(appointmentId) {
      this.$refs.appointmentModals.startUpdatingAppt(appointmentId);
    },

    startCancellingAppt(appointmentId) {
      this.$refs.appointmentModals.startCancellingAppt(appointmentId);
    },

    startReschedulingAppt(appointmentId, consultationId) {
      this.$refs.appointmentModals.startReschedulingAppt(appointmentId, consultationId);
    },

    requestProposal(possibleLawyerId, receipt) {
      const params = {
        id: possibleLawyerId,
        possibleLawyer: { proposalRequested: true }
      };

      PossibleLawyer.update(params).then(() => {
        this.broadcastAppointmentsChanged();
        receipt.message.displayButtons = false;
        receipt.showSuccessMessage = true;
      });
    },

    sendMessage() {
      LoadingService.loading('sendMessage');

      return this.savingPromise.then(() => {
        this.isSaving = true;

        return this.onCreateOrUpdateMessage(true).then(() => {
          LoadingService.done('sendMessage');
          this.scrollToLastMessage();
          this.isSaving = false;
        });
      });
    }
  }
};
</script>
