<template>
  <div>
    <div class="notification-header tiny-header-text">
      You Sent Availability For Your {{ meetingType }}
    </div>

    <div class="notification-content">
      <div class="text-italic size-text-13px">
        You sent availability for your {{ meetingType }} with <span class="semibold-weight">{{ otherParticipantsList }}</span>.<span v-if="showButtons"> Until they confirm a time, you can update your availability by clicking below.</span>
      </div>

      <div class="top-20" v-if="showButtons">
        <button type="button" class="nv-button-blue smaller" @click="onUpdateAvailability(appointmentMetadata.id)">Update Your Availability</button>
      </div>
    </div>
  </div>
</template>

<script>
import Appointment from 'resources/marketplace/appointment.js';

export default {
  name: 'AvailabilitySentConfirmationNotification',

  props: {
    appointmentMetadata: {
      type:     Object,
      required: true
    },

    otherParticipantsList: {
      type:     String,
      required: true
    },

    onUpdateAvailability: {
      type:    Function,
      default: () => {}
    },

    showButtons: {
      type:    Boolean,
      default: false
    }
  },

  computed: {
    appointmentResource() {
      return new Appointment(this.appointmentMetadata);
    },

    meetingType() {
      return this.appointmentResource.typeText();
    }
  }
};
</script>
