import BaseResource from 'resources/base-resource';

class Client extends BaseResource {
  static baseUrl = '/clients/:id.json';

  initials() {
    if (this.firstName && this.lastName) {
      return (this.firstName[0] + this.lastName[0]).toUpperCase();
    }
    else {
      return '@';
    }
  }
}

Client.addPostRoute('addStripeCard',        '/add_stripe_card');
Client.addPostRoute('addPlaidBankAccount',  '/add_plaid_bank_account');
Client.addPostRoute('addStripeBankAccount', '/add_stripe_bank_account');

Client.addPutRoute('updatePassword', '/password');

export default Client;
