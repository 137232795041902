<template>
  <svg viewBox="0 0 54 54" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient x1="0%" y1="100%" x2="99.392%" y2=".608%" id="ig-a"><stop stop-color="#0A1370" offset="0%" /><stop stop-color="#9213B2" offset="99.945%" /></linearGradient><linearGradient x1="0%" y1="80.609%" x2="99.392%" y2="19.763%" id="ig-b"><stop stop-color="#0A1370" offset="0%" /><stop stop-color="#9213B2" offset="99.945%" /></linearGradient></defs><g fill="none" fill-rule="evenodd"><circle :stroke="absoluteUrl('ig-a')" stroke-width="2" cx="27" cy="27" r="26" /><path d="M29.288 30.456c3.005.023 5.645.938 8.003 2.686.17.126.34.258.48.413.319.353.296.94-.014 1.301-.351.41-.981.46-1.41.12a11.358 11.358 0 00-4.785-2.259c-.474-.1-.96-.159-1.442-.195-.467-.034-.94-.04-1.407-.015-1.44.08-2.81.445-4.112 1.065-.822.392-1.582.88-2.303 1.435-.585.452-1.443.125-1.584-.623-.08-.419.07-.76.402-1.02a13.405 13.405 0 015.284-2.566c.7-.165 1.409-.268 2.127-.31.274-.016.549-.023.76-.032zm-8.698-5.348c.002.513 0 1.025 0 1.538v.105h1.577c.188 0 .368.03.536.124.384.215.576.63.5 1.094-.065.396-.406.73-.82.798-.072.012-.148.01-.222.01l-.73.001h-.84v1.614a1.008 1.008 0 01-1.053 1.026c-.541-.015-.965-.46-.967-1.024l-.002-.747v-.866h-1.52a1.04 1.04 0 01-.506-.108 1.016 1.016 0 01-.533-1.039c.067-.46.361-.777.806-.865.078-.015.16-.015.24-.015h1.517v-.107c0-.504-.002-1.008 0-1.512a.999.999 0 01.757-.991 1.01 1.01 0 011.26.964zM29.436 18c2.454.009 4.674 1.747 5.3 4.211a5.61 5.61 0 01-3.986 6.827 5.503 5.503 0 01-2.127.132c-2.206-.28-4.09-1.923-4.664-4.151a5.616 5.616 0 014.083-6.862c.43-.106.868-.151 1.394-.157zm-.086 2.028c-2.022.011-3.535 1.656-3.556 3.54-.022 1.946 1.533 3.608 3.524 3.623 2.002.015 3.555-1.619 3.582-3.53.027-1.928-1.504-3.618-3.55-3.633z" :fill="absoluteUrl('ig-b')" /></g></svg>
</template>

<script>
export default {
  name: 'SvgInviteGradient',

  methods: {
    absoluteUrl: function (id) {
      return `url(${window.location.href}#${id})`;
    }
  }
};
</script>
