<template>
  <modal v-model="modalOpen" size="lg" :header="false" :footer="false" :backdrop="false" :keyboard="false" append-to-body>
    <modal-close-button :on-dismiss="dismissModal"></modal-close-button>

    <div class="appointment-modal">
      <div v-show="!submitted">
        <div class="modal-header">
          You have received a {{ appointmentType }} request from {{ suggesterName }}
        </div>

        <choose-time
          ref="timeSelectors"
          :appointment="appointment"
          :on-suggest-alternative-times="onSuggestAlternativeTimes"
          :on-request-cancellation="onRequestCancellation"
          :on-submit="submit">
        </choose-time>
      </div>

      <div v-show="submitted">
        <choose-time-confirmation
          :appointment="appointment"
          :selected-timeslot="selectedTimeslot"
          :on-dismiss="closeModal">
        </choose-time-confirmation>
      </div>
    </div>
  </modal>
</template>

<script>
import ModalCloseButton from 'vue-app/shared/components/modals/modal-close-button.vue';
import ChooseTime from 'vue-app/marketplace/shared/appointments/choose-time.vue';
import ChooseTimeConfirmation from 'vue-app/marketplace/shared/appointments/choose-time-confirmation.vue';
import modalToggle from 'vue-app/shared/mixins/modal-toggle.js';

export default {
  name: 'ChooseTimeModal',

  components: {
    ModalCloseButton,
    ChooseTime,
    ChooseTimeConfirmation
  },

  mixins: [
    modalToggle
  ],

  props: {
    appointment: {
      type: Object,
      required: true
    },

    onSuggestAlternativeTimes: {
      type: Function,
      required: true
    },

    onRequestCancellation: {
      type: Function,
      required: true
    },

    onSubmit: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      appointmentCopy: {},
      selectedTimeslot: {},
      submitted: false
    };
  },

  computed: {
    appointmentType() {
      return this.appointment.typeText();
    },

    suggester() {
      return this.appointment.suggester().participant;
    },

    suggesterName() {
      return `${this.suggester.firstName} ${this.suggester.lastName}`;
    }
  },

  methods: {
    initModal() {
      this.$refs.timeSelectors.reset();
      this.submitted = false;
      this.openModal();
    },

    submit(timeslot) {
      return this.onSubmit(timeslot).then(() => {
        this.selectedTimeslot = timeslot;
        this.submitted = true;
      });
    }
  }
};
</script>
