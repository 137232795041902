import axios from 'axios';
import BaseResource from 'resources/base-resource';
import MatterClient from 'resources/marketplace/matter-client.js';
import MatterRatePackage from 'resources/marketplace/matter-rate-package.js';
import ConfigService from 'vue-app/shared/services/config-service.js';
import { isNil } from 'lodash';

class Matter extends BaseResource {
  static baseUrl = '/matters/:id.json';
  static urlTransformations = ['id'];

  static  totalAmountsByTypes(opts) {
    return axios.get(`/matters/${opts.id}/total_amounts_by_types.json`);
  }

  attributes() {
    return { ...this };
  }

  constructor(data) {
    super(data);

    this.matterClients = (this.matterClients || []).map(c => new MatterClient(c));
    this.matterRatePackages = (this.matterRatePackages || []).map(r => new MatterRatePackage(r));
  }

  isLegacyHourlyMatter() {
    return this.ratePackageType === 'LegacyHourlyRatePackage';
  }

  isLegacyFlatRateMatter() {
    return this.ratePackageType === 'LegacyStandardFlatRatePackage';
  }

  isLegacyMatter() {
    return this.isLegacyHourlyMatter() || this.isLegacyFlatRateMatter();
  }

  isLawyerAccepted() {
    return this.status === 'lawyer_accepted';
  }

  isLawyerRejected() {
    return this.status === 'lawyer_rejected';
  }

  isClientAccepted() {
    return this.status === 'client_accepted';
  }

  isClientRejected() {
    return this.status === 'client_rejected';
  }

  isRejected() {
    return this.isClientRejected() || this.isLawyerRejected();
  }

  isOpen() {
    return this.status === 'open';
  }

  isClosed() {
    return this.status === 'closed';
  }

  isLoaded() {
    return this.isClosed() || this.isOpen() || this.isLawyerAccepted();
  }

  isClosedOrRejected() {
    return this.isClosed() || this.isClientRejected() || this.isLawyerRejected();
  }

  isOpenOrClosed() {
    return this.isOpen() || this.isClosed();
  }

  notYetOpened() {
    return this.isLawyerAccepted() || this.isClientAccepted();
  }

  isReviewed() {
    return !!this.review;
  }

  populateBillingInfo(copyable) {
    const c = copyable || {};

    this.abaCodesRequired = (isNil(c.abaCodesRequired) ? false : c.abaCodesRequired);
    this.allowCreditCard = (isNil(c.allowCreditCard) ? true : c.allowCreditCard);
    this.autochargingAllowed = (isNil(c.autochargingAllowed) ? true : c.autochargingAllowed);
    this.autoinvoicingAllowed = (isNil(c.autoinvoicingAllowed) ? true : c.autoinvoicingAllowed);
    this.autoinvoicingDay = c.autoinvoicingDay || 7;
    this.hideDescriptions = (isNil(c.hideDescriptions) ? true : c.hideDescriptions);
    this.invoiceMethod = c.invoiceMethod;
    this.invoiceMethodDetail = c.invoiceMethodDetail;
    this.invoiceNote = c.invoiceNote;
    this.invoiceRecipient = c.invoiceRecipient || 'default';
    this.managementFeePercent = (isNil(c.managementFeePercent) ? ConfigService.defaultManagementFeePercent : c.managementFeePercent);
    this.netDaysPayable = c.netDaysPayable || 14;
    this.paymentMethodRequired = (isNil(c.paymentMethodRequired) ? true : c.paymentMethodRequired);
    this.paymentMethodType = c.paymentMethodType;
    this.invoiceDeliveryFrequency = c.invoiceDeliveryFrequency;

    if (c.legalArrangementRider) {
      this.legalArrangementRider = Object.assign({}, c.legalArrangementRider);
      this.legalArrangementRider.id = null;
    }
  }

  lawyerDisplayStatus() {
    if (this.isClientRejected()) {
      return 'rejected by the client';
    }
    else if (this.isLawyerRejected()) {
      return 'rejected by you';
    }
    else {
      return this.status;
    }
  }

  invoiceToPrioriOnly() {
    return this.invoiceRecipient === 'priori_only';
  }

  defaultInvoiceRecipients() {
    let recipients = this.matterClients.flatMap((mc) => mc.isInvoiceRecipient ? mc.client : []);
    if (this.requesterIsInvoiceRecipient) { recipients.push(this.client); }

    return recipients;
  }

  defaultInvoiceRecipientEmails() {
    return this.defaultInvoiceRecipients().map((recipient) => recipient.email);
  }

  possibleInvoiceRecipients() {
    const recipients = this.matterClients.flatMap(matterClient => {
      if (matterClient.client.deactivatedAt || matterClient.isInvoiceRecipient) {
        return [];
      }

      return matterClient.client;
    });

    if (!this.requesterIsInvoiceRecipient) { recipients.push(this.client); }

    return recipients;
  }

  allInvoiceRecipients() {
    return this.defaultInvoiceRecipientEmails().concat(this.invoiceRecipients);
  }

  convertEstimatesToFloats() {
    this.lowerHourlyEstimate = parseFloat(this.lowerHourlyEstimate);
    this.upperHourlyEstimate = parseFloat(this.upperHourlyEstimate);
    this.lowerFeeEstimate = parseFloat(this.lowerFeeEstimate);
    this.upperFeeEstimate = parseFloat(this.upperFeeEstimate);
  }

  uploadEngagementLetter(file, coord, signature, successFn, failFn) {
    var matter = this;

    if (coord) {
      Document.updateEngagementLetter('lawyer', matter.id, file, signature, coord,
        function (response) {
          successFn(response);
        },
        function (error) {
          failFn(error);
        }
      );
    }
    else {
      var documentAttrs = { matterId: matter.id, file: file.name, engagementLetter: true, name: 'Engagement Letter', documentSource: 'Priori', autoShareWithClient: true };

      Document.save(documentAttrs, function (document) {
        matter.uploadEngagementLetterToS3(file, document.id).then(
          function () {
            successFn(document);
          },
          function () {
            failFn(document);
            document.$delete();
          }
        );
      });
    }
  }

  // The Upload object isn't available in Vue, so an alternative
  // is needed. Out of scope for current changes, so I'm commenting this out
  // as a form of communicating that the current status isn't workable
  // and to provoke a `uploadEngagementLetterToS3 is not a function` error if
  // anyone tries. - YS 10/23
  //
  // uploadEngagementLetterToS3(file, id) {
  //   return Upload.upload({
  //     url: `/documents/${id}`,
  //     method: 'PUT',
  //     data: { document: file }
  //   });
  // }

  toggleClosed(successFn, failFn, closedFromLink) {
    const matter = this;

    if (matter.isOpen()) {
      matter.$close({ closedFromLink: closedFromLink }, successFn, failFn);
    }
    else if (matter.isClosed()) {
      matter.$reopen(successFn, failFn);
    }
  }

  deleteEngagementLetter(id, successFn, failFn) {
    Document.delete({ id: id }, successFn, failFn);
  }

  displayStatus() {
    switch (this.status) {
      case 'open':
        return 'Matter Open';

      case 'closed':
      case 'admin_closed':
        return 'Matter Closed';

      default:
        return this.status;
    }
  }
}

Matter.addPostRoute('openWithoutPayment');
Matter.addPostRoute('open');

export default Matter;
