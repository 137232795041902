<template>
  <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g fill="none" fill-rule="evenodd"><mask :id="maskId" fill="#fff"><circle cx="25" cy="25" r="25" /></mask><circle cx="25" cy="25" r="25" fill="#eee" /><g :mask="maskUrl" fill="#ccc"><path d="M9.071 38.201c-.158-1.767-.07-3.484.445-5.137.322-1.031 1.143-1.699 2.15-2.015 1.432-.45 2.9-.782 4.339-1.213.853-.258 1.718-.537 2.516-.934 1.808-.902 2.107-2.204 1.265-4.063-.934-2.063-1.794-4.171-2.499-6.321-.646-1.966-.67-4.017-.14-6.055.765-2.95 2.945-4.931 5.957-5.346 2.183-.3 4.268-.076 6.121 1.272 2.764 2.01 3.281 4.902 2.96 8.031-.3 2.908-1.349 5.607-2.7 8.178-1.158 2.203-.722 3.6 1.577 4.51 1.499.591 3.09.94 4.641 1.402.636.19 1.297.329 1.899.596 1.172.518 2.038 1.344 2.182 2.715.152 1.437.273 2.876.414 4.38H9.07Z" /><path d="m9 37.517 30.934-2.124L41 47.312l-32-.127z" /></g><path fill="#ccc" :mask="maskUrl" d="M14 42h24v8H14z" /></g></svg>
</template>

<script>
import { uniqueId } from 'lodash';
export default {
  name: 'SvgDefaultProfile',

  data() {
    return {
      maskId: `default-profile-${uniqueId()}`
    };
  },

  computed: {
    maskUrl: function () {
      return `url(${window.location.href}#${this.maskId})`;
    }
  }
};
</script>
