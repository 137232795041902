<template>
  <button type="submit" :class="lbClass" :disabled="lbDisabled || isLoading" @click.prevent="$emit('lb-click')">
    <span><slot></slot></span>
    <span :class="['spinner', { 'dark': dark }]" v-show="isLoading"></span>
  </button>
</template>

<script>
import LoadingService from 'vue-app/shared/services/loading-service';

export default {
  name: 'LoadingButton',

  props: {
    name: {
      type: String,
      required: true
    },

    lbClass: {
      type: [Object, Array, String]
    },

    lbDisabled: {
      type: Boolean,
      default: false
    },

    dark: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isLoading() {
      return LoadingService.isLoading(this.name);
    }
  }
};
</script>

<style lang="scss" scoped>
  .spinner {
    margin-left: 10px;
  }
</style>
