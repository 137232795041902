<template>
  <div>
    <div class="notification-header tiny-header-text">
      Rescheduling Your {{ appointmentType }}
    </div>

    <div class="notification-content">
      <div class="text-italic size-text-13px">
        <div class="bottom-20" data-testid="reschedule-reason-prompt">
          You have requested to reschedule your {{ appointmentType }} with <span class="semibold-weight">{{ otherParticipantsList }}</span> for the following reason:
        </div>

        <div class="semibold-weight">
          {{ consultation.reasonForCancel || '—' }}
        </div>
      </div>

      <div class="top-20" v-if="showButtons">
        <div class="text-italic size-text-13px bottom-20">
          Until they confirm a new time, you can update your availability by clicking below.
        </div>

        <button type="button" class="nv-button-blue smaller" @click="onUpdateAvailability(appointmentMetadata.id)">Update Your Availability</button>
      </div>
    </div>
  </div>
</template>

<script>
import Appointment from 'resources/marketplace/appointment.js';

export default {
  name: 'ConsultationRescheduleSentNotification',

  props: {
    appointmentMetadata: {
      type: Object,
      required: true
    },

    consultation: {
      type: Object,
      required: true
    },

    otherParticipantsList: {
      type: String,
      required: true
    },

    showButtons: {
      type:    Boolean,
      default: false
    },

    onUpdateAvailability: {
      type: Function,
      required: true
    }
  },

  computed: {
    appointmentResource() {
      return new Appointment(this.appointmentMetadata);
    },

    appointmentType() {
      return this.appointmentResource.typeText();
    }
  }
};
</script>
