<template>
  <div>
    <div class="modal-header">
      <div>
        <div class="bottom-5">
          You have scheduled a {{ appointmentType }} with
        </div>

        <div>
          {{ otherParticipantNames }}
        </div>
      </div>
    </div>

    <div class="appointment-container">
      <div class="row top-10 bottom-50">
        <div class="col-md-10 col-md-offset-1 size-text-base text-center">
          <div class="bottom-20">
            Your {{ appointmentType }} with {{ otherParticipantNames }} is scheduled for {{ startsAt }}. You will receive an email confirmation with the {{ appointmentType }}  information. Those details are also available on your account dashboard.
          </div>
        </div>
      </div>

      <div class="row bottom-10">
        <div class="col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4">
          <button type="button" class="nv-button-white smaller" @click="onDismiss">Close Window</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';
import { isEmpty } from 'lodash';

export default {
  name: 'ChooseTimeConfirmation',

  props: {
    appointment: {
      type: Object,
      required: true
    },

    selectedTimeslot: {
      type: Object,
      required: true
    },

    onDismiss: {
      type: Function,
      required: true
    }
  },

  computed: {
    ...mapState(useCurrentUserStore, { user: 'currentUser', ianaTimeZone: 'ianaTimeZone' }),

    appointmentType() {
      return this.appointment.typeText();
    },

    otherParticipants() {
      return this.appointment.participantsOtherThan(this.user).map(ap => ap.participant);
    },

    otherParticipantNameList() {
      return this.otherParticipants.map(p => this.nameAndTitleFor(p));
    },

    otherParticipantNames() {
      return this.otherParticipantNameList.join(', ');
    },

    startsAt() {
      if (isEmpty(this.selectedTimeslot)) { return; }
      return this.selectedTimeslot.label({ timeZone: this.ianaTimeZone, format: 'DATETIME_FULL' });
    }
  },

  methods: {
    nameAndTitleFor(participant) {
      const titleComponents = [participant.firstName, participant.lastName];

      if (participant.klass === 'Client' && participant.company && participant.company.length) {
        titleComponents.push(`(${participant.company})`);
      }

      return titleComponents.join(' ');
    }
  }
};
</script>
