<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 21"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.75" d="M17.625 2.583H1.792A.792.792 0 0 0 1 3.375v15.833c0 .438.354.792.792.792h15.833a.792.792 0 0 0 .792-.792V3.375a.792.792 0 0 0-.792-.792ZM14.458 1v3.167M4.958 1v3.167M1 7.333h17.417" /><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.75" d="m6.146 13.667 2.375 2.375 4.75-4.75" /></svg>
</template>

<script>
export default {
  name: 'SvgCalendarCheck'
};
</script>

<style scoped lang="scss">
  path {
    fill: none;
    stroke: #333;
  }
</style>
