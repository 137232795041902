<template>
  <div>
    <div class="photo-container larger single" v-if="singleParticipant">
      <profile-photo :user="participants[0]"></profile-photo>
    </div>

    <div class="photo-container larger multiple" v-if="multipleParticipants">
      <div class="inner-container first">
        <profile-photo :user="participants[0]" :small="true"></profile-photo>
      </div>

      <div class="inner-container second">
        <profile-photo :user="participants[1]" :small="true"></profile-photo>
      </div>
    </div>
  </div>
</template>

<script>
import ProfilePhoto from 'vue-app/shared/components/profile-photo.vue';

export default {
  name: 'ParticipantPhotos',

  components: {
    ProfilePhoto
  },

  props: {
    participants: {
      type: Array,
      required: true
    }
  },

  computed: {
    singleParticipant() {
      return this.participants.length === 1;
    },

    multipleParticipants() {
      return this.participants.length > 1;
    }
  }
};
</script>
