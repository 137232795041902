<template>
  <div class="clear-panel">
    <div class="text-center bottom-20">
      <div class="size-text-base semibold-weight">
        {{ dayOfWeek }}
      </div>

      <div>
        {{ dayOfMonth }}
      </div>
    </div>

    <div class="bottom-10">
      <button v-if="allTimesSelected" type="button" class="nv-button-white smaller no-transform" @click="deselectAllTimes">Deselect All</button>

      <button v-else type="button" class="nv-button-white smaller no-transform" @click="selectAllTimes" :disabled="allTimesUnavailable">Select All</button>
    </div>

    <div v-for="timeslot in sortedTimeslots" class="list-spacing-10">
      <availability-selection-hour
        :timeslot="timeslot"
        :availability="availability">
      </availability-selection-hour>
    </div>
  </div>
</template>

<script>
import AvailabilitySelectionHour from 'vue-app/marketplace/shared/appointments/availability-selection-hour.vue';
import { intersectionWith, sortBy } from 'lodash';

export default {
  name: 'AvailabilitySelectionDay',

  components: {
    AvailabilitySelectionHour
  },

  props: {
    date: {
      type: Object,
      required: true
    },

    timeslots: {
      type: Array,
      required: true
    },

    availability: {
      type: Object,
      required: true
    }
  },

  computed: {
    dayOfWeek() {
      return this.date.toFormat('EEEE');
    },

    dayOfMonth() {
      return this.date.toFormat('MMMM d');
    },

    sortedTimeslots() {
      return sortBy(this.timeslots, 'time.ts');
    },

    activeTimeslots() {
      return this.timeslots.filter(ts => this.availability.isAvailable(ts));
    },

    selectedTimeslots() {
      return intersectionWith(this.availability.timeslots, this.timeslots, (ts, otherTs) => {
        return ts.equals(otherTs);
      });
    },

    allTimesUnavailable() {
      return this.activeTimeslots.length === 0;
    },

    allTimesSelected() {
      return this.selectedTimeslots.length === this.activeTimeslots.length;
    }
  },

  methods: {
    selectAllTimes() {
      this.activeTimeslots.forEach(timeslot => this.availability.add(timeslot));
    },

    deselectAllTimes() {
      this.timeslots.forEach(timeslot => this.availability.remove(timeslot));
    }
  }
};
</script>
