class UserSchedule {
  // schedule needs to be in UTC time, which is how we store schedule times
  // on the backend
  constructor(schedule) {
    this.schedule = schedule;
  }

  availableAt(timeslot) {
    return !this.busyTimesOn(timeslot.date({ utc: true })).includes(timeslot.hour({ utc: true }));
  }

  busyTimesOn(dateStr) {
    return [...this.consultationsOn(dateStr), ...this.unavailabilitiesOn(dateStr)];
  }

  consultationsOn(dateStr) {
    return this.schedule.consultations[dateStr] || [];
  }

  unavailabilitiesOn(dateStr) {
    return this.schedule.unavailabilities[dateStr] || [];
  }
}

export default UserSchedule;
