import { defineStore } from 'pinia';

export default defineStore('notification', {
  state: () => {
    const storedData = JSON.parse(sessionStorage.getItem('notificationMessage')) || {};

    return {
      notification: {
        message: storedData.message,
        severity: storedData.severity
      },

      keepAlive: false
    };
  },

  getters: {
    hasNotification() {
      return !!this.notification.message;
    }
  },

  actions: {
    setNotification(notification) {
      this.notification = notification;
    },

    clearNotification() {
      if (!this.keepAlive) {
        this.notification = {
          message: null,
          severity: null
        };
      }

      this.keepAlive = false;
    },

    setKeepAlive(value) {
      this.keepAlive = value;
    }
  }
});
