<template>
  <div>
    <div class="modal-header">
      <div>
        <div class="bottom-10">
          <participant-photos :participants="selectedParticipants"></participant-photos>
        </div>

        <div>
          {{ isInPersonMeeting ? 'In-person meeting' : 'Call' }} request sent
        </div>
      </div>
    </div>

    <div class="appointment-container">
      <div class="row top-10 bottom-50">
        <div class="col-md-8 col-md-offset-2 size-text-base text-center">
          Thank you for submitting your request for {{ isInPersonMeeting ? 'an in-person meeting' : 'a call' }}. You will be notified as soon as the other party responds to your request.
        </div>
      </div>

      <div class="row bottom-10">
        <div class="col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4">
          <button type="button" class="nv-button-white smaller" @click="onCloseModal">Close Window</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ParticipantPhotos from 'vue-app/marketplace/shared/appointments/participant-photos.vue';

export default {
  name: 'SchedulingConfirmation',

  components: {
    ParticipantPhotos
  },

  props: {
    appointment: {
      type: Object,
      required: true
    },

    selectedParticipants: {
      type: Array,
      required: true
    },

    onCloseModal: {
      type: Function,
      required: true
    }
  },

  computed: {
    isInPersonMeeting() {
      return this.appointment.isInPersonMeeting();
    }
  }
};
</script>
