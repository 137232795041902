import axios from 'axios';
import BaseResource from 'resources/base-resource';
import Lawyer from 'resources/lawyer.js';
import LawFirm from 'resources/marketplace/law-firm.js';
import Matter from 'resources/marketplace/matter.js';
import Appointment from 'resources/marketplace/appointment.js';
import MatterRatePackage from 'resources/marketplace/matter-rate-package.js';
import CounselRequest from 'resources/marketplace/counsel-request.js';
import RatePackage from 'resources/marketplace/rate-package.js';
import { find, get, isEmpty, map } from 'lodash';

class PossibleLawyer extends BaseResource {
  static baseUrl = '/possible_lawyers/:id.json';

  static reject(id, data) {
    const url = `${this.baseUrl.replace(':id.json', id)}/reject.json`;
    return axios.put(url, data);
  }

  constructor(data) {
    super(data);

    if (this.lawyer) { this.lawyer = new Lawyer(this.lawyer); }

    this.matter = this.matter || null;

    if (!isEmpty(this.lawFirm)) { this.lawFirm = new LawFirm(this.lawFirm); }
    if (!isEmpty(this.appointment)) { this.appointment = new Appointment(this.appointment); }
    if (!isEmpty(this.counselRequest)) { this.counselRequest = new CounselRequest(this.counselRequest); }
  }

  statusForLawyer() {
    if (this.scheduledStatus()) {
      return 'Call Scheduled';
    }
    else if (this.awaitingProposalStatus()) {
      return 'Client Awaiting Proposal';
    }
    else if (this.rateSentStatus()) {
      return 'Sent Proposal';
    }
    else if (this.requestedStatus()) {
      return 'Needs Call Confirmation';
    }
    else if (this.messagedStatus()) {
      return 'Message Received';
    }
    else if (this.lawyerSuggestedAlternatives()) {
      return 'Suggested New Times';
    }
    else if (this.cancelledStatus()) {
      return 'Call Cancelled';
    }
    else {
      return 'Rescheduling Call';
    }
  }

  interactingClient() {
    if (!this.appointment) { return; }

    return find(this.appointment.appointmentParticipantsAttributes, (ap) => { return ap.participant.klass === 'Client'; }).participant;
  }

  isCurrentClientInteracting(currentClient) {
    const interactingClient = this.interactingClient();
    return interactingClient && (currentClient || {}).id === interactingClient.id;
  }

  lawyerProfile() {
    return get(this, 'lawyer.lawyerProfile');
  }

  projectManager() {
    return get(this, 'counselRequest.projectManager');
  }

  crMatterOpen() {
    return get(this, 'counselRequest.matterOpen') && this.counselRequest.matterOpen();
  }

  crCompleted() {
    return get(this, 'counselRequest.completed') && this.counselRequest.completed();
  }

  crAdminClosed() {
    return get(this, 'counselRequest.adminClosed') && this.counselRequest.adminClosed();
  }

  crRejectedMax() {
    return get(this, 'counselRequest.rejectedMax') && this.counselRequest.rejectedMax();
  }

  crDisabled() {
    return this.crRejectedMax();
  }

  unsentStatus() {
    return this.status === 'unsent';
  }

  suggestedStatus() {
    return this.status === 'suggested';
  }

  suggested() {
    return !this.crMatterOpen() && !this.crDisabled() && this.suggestedStatus();
  }

  messagedStatus() {
    return this.status === 'contacting' && !this.schedulingStatus && !this.appointment;
  }

  requestedStatus() {
    if ((this.appointment || {}).status) {
      return this.appointment.status() === 'alternatives_suggested';
    }

    return this.status === 'contacting' && this.schedulingStatus === 'appointment_requested';
  }

  requested() {
    return !this.crMatterOpen() && this.requestedStatus();
  }

  scheduledStatus() {
    if ((this.appointment || {}).status) {
      return this.appointment.status() === 'scheduled';
    }

    return this.status === 'contacting' && this.schedulingStatus === 'appointment_scheduled';
  }

  scheduled() {
    return !this.crMatterOpen() && this.scheduledStatus();
  }

  needsNewTimes() {
    return this.status === 'contacting' && this.schedulingStatus === 'conflict';
  }

  needsRescheduling() {
    if ((this.appointment || {}).status) {
      return this.appointment.status() === 'needs_rescheduling' || this.appointment.status() === 'needs_new_availability';
    }

    return this.status === 'contacting' && this.schedulingStatus === 'needs_rescheduling';
  }

  cancelledStatus() {
    if ((this.appointment || {}).status) {
      return this.appointment.status() === 'cancelled';
    }
  }

  lawyerSuggestedAlternatives() {
    if ((this.appointment || {}).status) {
      return this.appointment.status() === 'requested';
    }

    return this.status === 'contacting' &&
      this.schedulingStatus === 'lawyer_suggested_alternatives';
  }

  consultationCompletedOutside() {
    return this.status === 'awaiting_decision' &&
      this.schedulingStatus === 'consultation_completed_outside';
  }

  awaitingProposalStatus() {
    return this.status === 'awaiting_decision';
  }

  awaitingProposal() {
    return !this.crMatterOpen() && this.awaitingProposalStatus();
  }

  awaiting() {
    return this.requested() || this.awaitingProposal();
  }

  rateSentStatus() {
    return this.status === 'sent_price';
  }

  isDraft() {
    if (this.matter) {
      return this.matter.status === 'draft';
    }
    else {
      return false;
    }
  }

  clientAcceptedStatus() {
    return this.matter && this.matter.status === 'client_accepted';
  }

  rateSentNotSelectedStatus() {
    const status = get(this, 'matter.status');
    return this.rateSentStatus() && (status === 'lawyer_accepted' || status === 'client_accepted');
  }

  rateSent() {
    return !this.crMatterOpen() && this.rateSentNotSelectedStatus();
  }

  selected() {
    const matterStatus = get(this.matter, 'status');
    return this.rateSentStatus() && (matterStatus === 'open' || matterStatus === 'closed');
  }

  rejected() {
    return this.status === 'client_rejected' || this.status === 'lawyer_rejected';
  }

  rejectedByClient() {
    return this.status === 'client_rejected';
  }

  retiredOrRejected() {
    return this.retired || this.rejected();
  }

  ableToSchedule() {
    return ((this.suggestedStatus() || this.messagedStatus() || this.requestedStatus()) && !this.crMatterOpen()) ||
      ((this.needsNewTimes() || this.needsRescheduling() || this.lawyerSuggestedAlternatives()) &&
      !this.crAdminClosed());
  }

  ableToReject() {
    return !this.crRejectedMax() && !this.selected() && !this.crCompleted();
  }

  loadMatter() {
    if (!this.rateSentStatus()) {
      return Promise.resolve();
    }

    return Matter.query({ possible_lawyer_id: this.id }).then((matters) => {
      if (matters.length) {
        this.matter = matters[0];
        this.matter.matterRatePackages = map(this.matter.matterRatePackages, (matterRate) => {
          return new MatterRatePackage(matterRate);
        });
      }
    });
  }

  hasNoteAboutLawyer() {
    return this.noteAboutLawyer && this.noteAboutLawyer.length;
  }

  visibleRate() {
    if (this.visibleRatesAttributes.length) {
      let pkg = find(this.visibleRatesAttributes, { ratePackageType: 'HourlyRatePackage' });
      pkg = pkg || this.visibleRatesAttributes[0];
      return new RatePackage(pkg.ratePackage);
    }
    else if (this.clientRate) {
      return new RatePackage({ type: 'hourly', dollars: (this.clientRate / 100) });
    }
    else if (this.lawyer.clientRate) {
      return new RatePackage({ type: 'hourly', dollars: (this.lawyer.clientRate / 100) });
    }
    else {
      return new RatePackage({ type: '', dollars: 0 });
    }
  }

  hasAppointment() {
    return (this.appointment || {}).id;
  }

  sentMessage() {
    return !this.hasAppointment() && this.status === 'contacting';
  }
}

export default PossibleLawyer;
