import BillingFirmPracticeArea from 'resources/scout/billing-firm-practice-area';

class Scout {
  static isScout = true;
  static isFeatureLab = false;

  static queryPracticeAreas() {
    return BillingFirmPracticeArea.query();
  }
}

export default Scout;
