// Interaction modes reference docs:

// https://vee-validate.logaretm.com/v2/guide/interaction.html

const formInteractionModes = {
  methods: {
    passiveAggressive: ({ errors }) => {
      // start passive and become aggressive if the field is invalid.
      if (errors.length) {
        return {
          on: ['input', 'blur']
        };
      }

      return {
        on: []
      };
    }
  }
};

export default formInteractionModes;
