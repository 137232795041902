import BaseResource from 'resources/base-resource';
import useCurrentUserStore from 'vue-app/stores/current-user';
import { cloneDeep, every, find, filter, isEmpty } from 'lodash';

class Appointment extends BaseResource {
  static baseUrl = '/appointments/:id.json';

  static newAppointment() {
    return new Appointment({
      type: null,
      duration: 30,
      possibleLawyerId: null,
      appointmentParticipantsAttributes: [],
      appointmentLocationAttributes: {},
      conflictingTimes: { consultations: {}, unavailabilities: {} }
    });
  }

  isInPersonMeeting() {
    return this.type === 'in-person';
  }

  needsAvailability() {
    return this.status() === 'requested' || this.status() === 'needs_new_availability';
  }

  needsConfirmation() {
    return this.status() === 'alternatives_suggested';
  }

  needsRescheduling() {
    return this.status() === 'needs_rescheduling';
  }

  isScheduled() {
    return this.status() === 'scheduled';
  }

  isCompleted() {
    return this.status() === 'completed';
  }

  isCancelled() {
    return this.status() === 'cancelled';
  }

  isScheduling() {
    const status = this.status();
    return status === 'requested' || status === 'needs_new_availability' ||
      status === 'alternatives_suggested' || status === 'needs_rescheduling';
  }

  // DEPRECATED - uses state (current client) which is undesirable for a model/resource
  // instead use `participantFor`, defined below, and pass the current user
  currentParticipant() {
    const currentUser = useCurrentUserStore().currentUser;

    return find(this.appointmentParticipantsAttributes, {
      participantId: currentUser.id,
      participantType: currentUser.klass
    });
  }

  participantFor(user) {
    return this.appointmentParticipantsAttributes.find((ap) => {
      return ap.participantType === user.klass && ap.participantId === user.id;
    });
  }

  currentParticipantIsSuggesting() {
    return this.currentParticipant() === this.suggester();
  }

  currentParticipantIsOriginator() {
    return this.currentParticipant() === this.originator();
  }

  hasConsultations() {
    return !!this.consultationsAttributes.length;
  }

  scheduledConsultation() {
    return find(this.consultationsAttributes, (consultation) => { return consultation.status === 'scheduled' || consultation.status === 'waiting' || consultation.status === 'in_progress'; });
  }

  completedConsultation() {
    return find(this.consultationsAttributes, (consultation) => { return consultation.status === 'completed' || consultation.status === 'completed_outside'; });
  }

  cancelledConsultation() {
    return this.hasConsultations() && this.consultationsAttributes[this.consultationsAttributes.length - 1].status === 'cancelled';
  }

  consultationsExpired() {
    return this.hasConsultations() && every(this.consultationsAttributes, (consultation) => { return consultation.status === 'expired' || consultation.status === 'expired_and_old'; });
  }

  consultationsExpiredOrCancelled() {
    return this.cancelledConsultation() || this.consultationsExpired();
  }

  suggester() {
    return find(this.appointmentParticipantsAttributes, { suggesting: true }) || {};
  }

  originator() {
    return find(this.appointmentParticipantsAttributes, { originator: true }) || {};
  }

  // DEPRECATED - uses state (current client) which is undesirable for a model/resource
  // instead use `participantsOtherThan`, defined below, and pass current user
  otherParticipants() {
    const currentUser = useCurrentUserStore().currentUser;

    return filter(this.appointmentParticipantsAttributes, (ap) => {
      return !(ap.participantId === currentUser.id && ap.participantType === currentUser.klass);
    });
  }

  participantsOtherThan(user) {
    return this.appointmentParticipantsAttributes.filter((ap) => {
      return !(ap.participantId === user.id && ap.participantType === user.klass);
    });
  }

  needsNewAvailability() {
    return this.appointmentParticipantsAttributes.every((ap) => { return isEmpty(ap.availability); });
  }

  lastAvailabilityPassed() {
    return find(this.appointmentParticipantsAttributes, (ap) => { return ap.availabilityReminderSentAt; });
  }

  location() {
    return this.appointmentLocationAttributes || {};
  }

  status() {
    if (this.cancelledAt) {
      return 'cancelled';
    }
    else if (!this.hasConsultations()) {
      if (this.lastAvailabilityPassed()) {
        return 'needs_new_availability';
      }
      else if (this.currentParticipantIsSuggesting()) {
        return 'requested';
      }
      else if (!this.currentParticipantIsSuggesting()) {
        return 'alternatives_suggested';
      }
    }
    else if (this.hasConsultations()) {
      if (this.consultationsExpiredOrCancelled()) {
        if (this.needsNewAvailability()) {
          return 'needs_rescheduling';
        }
        else if (this.lastAvailabilityPassed()) {
          return 'needs_new_availability';
        }
        else if (this.currentParticipantIsSuggesting()) {
          return 'requested';
        }
        else if (!this.currentParticipantIsSuggesting()) {
          return 'alternatives_suggested';
        }
      }
      else if (this.completedConsultation()) {
        return 'completed';
      }
      else if (this.scheduledConsultation()) {
        return 'scheduled';
      }
    }
  }

  possibleLawyerStatus() {
    if (this.possibleLawyerId) {
      var clientParticipant = find(this.appointmentParticipantsAttributes, { participantType: 'Client' });
      var lawyerParticipant = find(this.appointmentParticipantsAttributes, { participantType: 'Lawyer' });

      if (!this.hasConsultations()) {
        if (clientParticipant.suggesting) {
          return 'client_suggesting';
        }
        else if (lawyerParticipant.suggesting) {
          return 'lawyer_suggesting';
        }
      }
      else if (this.hasConsultations()) {
        if (this.completedConsultation()) {
          return 'completed';
        }
        else if (this.scheduledConsultation()) {
          return 'scheduled';
        }
        else {
          return 'needs_rescheduling';
        }
      }
    }
  }

  typeText() {
    if (this.type === 'phone') {
      return 'call';
    }
    else if (this.type === 'video') {
      return 'video call';
    }

    return 'meeting';
  }

  buttonText() {
    if (this.type === 'phone') {
      return 'Call';
    }
    else if (this.type === 'video') {
      return 'Video Call';
    }

    return 'Meeting';
  }

  participantAttrsFor(participant, isOriginator, isSuggesting, timesByDate, note) {
    return {
      participantType: participant.klass,
      participantId: participant.id,
      originator: isOriginator || false,
      suggesting: isSuggesting || false,
      availability: timesByDate || {},
      note: note
    };
  }

  submitSuggestedTimes(originator, availability, selectedParticipants) {
    const promises = [];
    const timesByDate = availability.validAvailability().timesByDate();
    const originatorAttrs = this.participantAttrsFor(originator, true, true, timesByDate, availability.note);

    selectedParticipants.forEach((participant) => {
      const appointment = cloneDeep(this);

      if (isEmpty(appointment.appointmentLocationAttributes)) {
        delete appointment.appointmentLocationAttributes;
      }

      appointment.possibleLawyerId = participant.possibleLawyerId;
      appointment.appointmentParticipantsAttributes = [
        originatorAttrs, this.participantAttrsFor(participant)
      ];

      promises.push(this.constructor.save({ appointment: appointment }).then((appt) => {
        return appt;
      }));
    });

    return Promise.all(promises);
  }

  updateSuggestedTimes(participant, availability) {
    participant.suggesting = true;
    participant.note = availability.note;
    participant.availability = availability.validAvailability().timesByDate();

    const params = {
      id: this.id,
      appointment: {
        appointmentParticipantsAttributes: [{
          id: participant.id,
          participantId: participant.participantId,
          participantType: participant.participantType,
          availability: participant.availability,
          note: participant.note,
          suggesting: participant.suggesting
        }]
      }
    };

    return this.constructor.update(params);
  }

  reschedule(reason, consultation, availability, reschedulingParticipant) {
    const params = { id: this.id, appointment: {} };

    params.appointment['consultationsAttributes'] = [{
      id: consultation.id,
      status: 'cancelled',
      reasonForCancellation: reason,
      cancelledById: reschedulingParticipant.id
    }];

    params.appointment['appointmentParticipantsAttributes'] = [{
      id: reschedulingParticipant.id,
      participantId: reschedulingParticipant.participantId,
      participantType: reschedulingParticipant.participantType,
      availability: availability.timesByDate(),
      note: availability.note,
      suggesting: true,
      rescheduling: true
    }];

    return this.constructor.update(params);
  }

  cancel(reason, cancellingParticipant) {
    const params = {
      id: this.id,
      appointment: {
        cancelledAt: new Date(),
        reasonForCancellation: reason,
        cancelledById: cancellingParticipant.id
      }
    };

    return this.constructor.update(params);
  }
}

export default Appointment;
