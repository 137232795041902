import Vue from 'vue';

class LoadingService {
  constructor() {
    this._state = Vue.observable({
      loaders: {}
    });
  }

  loading(name) {
    if (this._state.loaders[name]) {
      this._state.loaders[name] += 1;
    }
    else {
      Vue.set(this._state.loaders, name, 1);
    }
  }

  done(name) {
    if (this._state.loaders[name]) {
      this._state.loaders[name] -= 1;
    }
  }

  around(name, loadingFunc) {
    this.loading(name);

    return loadingFunc().finally(() => {
      this.done(name);
    });
  }

  isLoading(name) {
    return this._state.loaders[name] > 0;
  }
}

const instance = new LoadingService();
Object.freeze(instance);

export default instance;
