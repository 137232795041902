<template>
  <svg viewBox="0 0 6 6" xmlns="http://www.w3.org/2000/svg"><path d="M4.208 3L5.75 1.459A.855.855 0 0 0 4.541.25L3 1.792 1.459.25A.854.854 0 1 0 .25 1.46L1.791 3 .251 4.541A.855.855 0 0 0 1.458 5.75l1.54-1.542L4.542 5.75a.854.854 0 1 0 1.207-1.21L4.21 3z" /></svg>
</template>

<script>
// NOTE: this is a copy of x3, but with the surrounding div removed. Our icon
// files should just contain the svg element so should replace all uses of x3
// with x4 (and then we could switch this back to x3)
export default {
  name: 'SvgX4'
};
</script>
