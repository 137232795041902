<template>
  <div v-if="modalOpen">
    <modal v-model="modalOpen" size="md-sm" :transition="0" :header="false" :footer="false" append-to-body>
      <div class="confirmation-modal">
        <div class="confirmation-header">
          {{ header }}
        </div>

        <div class="confirmation-message">
          {{ message }}
        </div>

        <div class="row">
          <div class="col-sm-4">
            <button type="button" class="nv-button-blue a-button-size" @click="confirm">{{ yesBtnText }}</button>
          </div>

          <div class="col-sm-3 top-20-xs">
            <button type="button" class="nv-button-white a-button-size" @click="dismissModal">{{ noBtnText }}</button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import modalToggle from 'vue-app/shared/mixins/modal-toggle';

export default {
  name: 'ConfirmationModal',

  mixins: [
    modalToggle
  ],

  props: {
    header: {
      type: String,
      default: 'Confirm'
    },

    message: {
      type: String,
      default: 'Are you sure?'
    },

    onConfirmAction: {
      type: Function,
      required: true
    },

    yesBtnText: {
      type: String,
      default: 'Yes'
    },

    noBtnText: {
      type: String,
      default: 'No'
    }
  },

  methods: {
    confirm() {
      this.onConfirmAction();
      this.closeModal();
    }
  }
};
</script>

<style scoped lang="scss">
  .confirmation-modal {
    padding: 20px;

    @media (min-width: 768px) {
      padding: 30px;
    }

    .confirmation-header {
      margin-bottom: 20px;
      border-radius: 6px 6px 0 0;
      font-size: 20px;
      line-height: 1.3;
    }

    .confirmation-message {
      margin-bottom: 40px;
      font-size: 14px;
    }
  }
</style>
