<template>
  <div>
    <div class="date-header size-text-11px semibold-weight dark-gray-text" v-if="shouldShowDate">
      {{ updatedDate }}
    </div>

    <message
      v-if="isMessage"
      :conversation="conversation"
      :receipt="receipt">
    </message>

    <notification
      v-if="isNotification"
      :receipt="receipt"
      :on-confirm-appointment="onConfirmAppointment"
      :on-update-availability="onUpdateAvailability"
      :on-cancel-appointment="onCancelAppointment"
      :on-reschedule-appointment="onRescheduleAppointment"
      :on-request-proposal="onRequestProposal">
    </notification>

    <attachment
      v-for="attachment in attachments"
      :key="attachment.id"
      :conversation="conversation"
      :receipt="receipt"
      :attachment="attachment">
    </attachment>
  </div>
</template>

<script>
import Message from 'vue-app/mailboxer/marketplace/message.vue';
import Notification from 'vue-app/mailboxer/marketplace/notification.vue';
import Attachment from 'vue-app/mailboxer/marketplace/attachment.vue';
import { DateTime } from 'luxon';

export default {
  name: 'ConversationMessage',

  components: {
    Message,
    Notification,
    Attachment
  },

  props: {
    conversation: {
      type: Object,
      required: true
    },

    receipt: {
      type: Object,
      required: true
    },

    isFirstMessage: {
      type: Boolean,
      default: false
    },

    previousMessage: {
      type: Object,
      required: false
    },

    onConfirmAppointment: {
      type: Function,
      required: true
    },

    onUpdateAvailability: {
      type: Function,
      required: true
    },

    onCancelAppointment: {
      type: Function,
      required: true
    },

    onRescheduleAppointment: {
      type: Function,
      required: true
    },

    onRequestProposal: {
      type: Function,
      required: true
    }
  },

  computed: {
    message() {
      return this.receipt.message;
    },

    attachments() {
      return this.message.attachments;
    },

    isMessage() {
      return this.message.type === 'message';
    },

    isNotification() {
      return this.message.type === 'notification';
    },

    messageUpdatedAt() {
      return DateTime.fromISO(this.message.updatedAt);
    },

    previousMessageUpdatedAt() {
      return DateTime.fromISO(this.previousMessage.updatedAt);
    },

    shouldShowDate() {
      return this.isFirstMessage || this.messageUpdatedAt.startOf('day') > this.previousMessageUpdatedAt.startOf('day');
    },

    updatedDate() {
      const now = DateTime.now();

      if (this.messageUpdatedAt.hasSame(now, 'day')) {
        return 'Today';
      }
      else if (this.messageUpdatedAt.hasSame(now.minus({ days: 1 }), 'day')) {
        return 'Yesterday';
      }
      else {
        return this.messageUpdatedAt.toLocaleString(DateTime.DATE_MED);
      }
    }
  }
};
</script>
