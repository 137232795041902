import BaseResource from 'resources/base-resource';

class MatterRatePackage extends BaseResource {
  static baseUrl = '/matters/:matterId/matter_rate_packages/:id.json';
  static urlTransformations = ['id', 'matterId'];

  equals(otherRate) {
    return this.uid === otherRate.uid;
  }

  isHourly() {
    return this.rateType === 'hourly';
  }

  isFlat() {
    return this.rateType === 'flat';
  }

  tag() {
    return this.isHourly() ? '/hr' : '';
  }
}

export default MatterRatePackage;
