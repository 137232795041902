<template>
  <svg viewBox="0 0 54 54" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><linearGradient x1="0%" y1="100%" x2="99.392%" y2=".608%" id="cg-a"><stop stop-color="#0A1370" offset="0%" /><stop stop-color="#9213B2" offset="99.945%" /></linearGradient><linearGradient x1=".797%" y1="100%" x2="98.605%" y2=".608%" id="cg-c"><stop stop-color="#0A1370" offset="0%" /><stop stop-color="#9213B2" offset="99.945%" /></linearGradient><path id="cg-b" d="M0 0h19.84v20H0z" /></defs><g fill="none" fill-rule="evenodd"><circle :stroke="absoluteUrl('cg-a')" stroke-width="2" cx="27" cy="27" r="26" /><g transform="translate(17 17)"><mask id="cg-d" fill="#fff"><use xlink:href="#cg-b" /></mask><path d="M12.619 3.63H7.222c0 .235.002.466-.001.697a.912.912 0 0 1-.382.764.895.895 0 0 1-.956.094.876.876 0 0 1-.531-.767c-.018-.233-.005-.467-.006-.701V3.63H1.932v4.561h16.03V3.63h-3.467v.68c0 .063 0 .126-.011.187-.088.484-.53.826-1.003.777a.941.941 0 0 1-.862-.962c.002-.226 0-.451 0-.682zM1.931 18.06h16.03v-7.983H1.93v7.983zM5.34 1.75c.003-.034.005-.062.005-.09.002-.275-.009-.551.007-.825.025-.433.407-.794.84-.832a.934.934 0 0 1 1.004.733c.016.073.023.149.023.224.003.26.001.519.001.784h5.395l.003-.082v-.67a.93.93 0 0 1 .738-.93.924.924 0 0 1 1.131.84c.015.25.005.5.006.749v.1h.11c1.274 0 2.547-.004 3.82.003a1.407 1.407 0 0 1 1.379 1.14c.024.117.035.237.035.356.002 5.036.002 10.072.002 15.108 0 .533-.193.982-.605 1.327a1.336 1.336 0 0 1-.823.309h-.117c-5.537 0-11.075-.003-16.612.005-.83.001-1.517-.615-1.656-1.342A1.574 1.574 0 0 1 0 18.363C0 13.325-.003 8.286.002 3.247c0-.647.35-1.082.92-1.353.23-.11.478-.145.733-.145 1.193.002 2.386.001 3.579.001h.107z" :fill="absoluteUrl('cg-c')" :mask="absoluteUrl('cg-d')" /></g></g></svg>
</template>

<script>
export default {
  name: 'SvgCalendarGradient',

  methods: {
    absoluteUrl: function (id) {
      return `url(${window.location.href}#${id})`;
    }
  }
};
</script>
