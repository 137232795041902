import axios from 'axios';
import BaseResource from 'resources/base-resource';
import SharedLists from 'vue-app/shared/services/shared-lists-service.js';
import { compact, filter, find, map, omit } from 'lodash';

class LawFirm extends BaseResource {
  static baseUrl = '/law_firms/:id.json';

  // mergeLawyer: { method: 'POST', url: '/law_firms/:id/merge_lawyer.json' },
  // adminUsers: { method: 'GET', url: '/law_firms/:id/admin_users.json', isArray: true }

  static lawyers(data) {
    const params = omit(data, 'id');
    return axios.get(`/law_firms/${data.id}/lawyers.json`, { params: params });
  }

  // uploadLogo(file) {
  //   var lawFirm = this;

  //   return Upload.upload({
  //     url: '/law_firms/' + lawFirm.id,
  //     method: 'PUT',
  //     data: {
  //       law_firm: {
  //         logo: file
  //       }
  //     }
  //   });
  // }

  static adminUsers(data) {
    return axios.get(`/law_firms/${data.id}/admin_users.json`, { params: data });
  }

  selectedAccreditations() {
    const options = SharedLists.rfpAccreditationOptions;
    return map(filter(options, (o) => { return this.firmAccreditations[o.name]; }), 'label');
  }

  lawyerInFirm(lawyer) {
    return find(this.lawyers, (l) => { return l.id === lawyer.id; });
  }

  formattedLocation(location) {
    return compact([location.city, location.state, location.country]).join(', ');
  }
}

export default LawFirm;
