<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="-269 20.2 360 360.8"><path class="ew-st1" d="M-89 20.2c-99.4 0-180 80.9-180 180.3v.1c0 99.4 80.6 180.3 180 180.3S91 300 91 200.6v-.1C91 101.1 10.4 20.2-89 20.2zM58 200.6c0 81.2-65.7 146.9-146.9 146.9h.3c-81.3.1-147.4-65.6-147.4-146.9v-.1c0-81.2 65.8-147.3 147-147.3S58 119.3 58 200.6z" /><path class="ew-st0" d="M-88.7 122.4c9.1 0 16.5 7.4 16.5 16.5v65c0 9.1-7.4 16.5-16.5 16.5s-16.5-7.4-16.5-16.5v-65c0-9.1 7.4-16.5 16.5-16.5z" /><circle class="ew-st0" cx="-88.2" cy="262.4" r="16.5" /></svg>
</template>

<script>
export default {
  name: 'SvgExclamationWhite'
};
</script>

<style scoped>
  .ew-st0 {
    fill: #fff;
  }

  .ew-st1 {
    fill: rgb( 255, 255, 255, .3);
  }
</style>
