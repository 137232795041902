const modalToggle = {
  data() {
    return {
      modalOpen: false
    };
  },

  methods: {
    openModal() {
      this.modalOpen = true;
      this.$emit('on-modal-open');
    },

    closeModal(msg) {
      this.modalOpen = false;
      this.$emit('on-modal-close', msg || 'done');
    },

    dismissModal(msg) {
      this.modalOpen = false;
      this.$emit('on-modal-close', msg || 'dismiss');
    }
  }
};

export default modalToggle;
