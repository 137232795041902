import axios from 'axios';
import { reduce, snakeCase } from 'lodash';
import CaseConverters from './utils/case-converters';

const digIntoData = (resp) => resp.data;
const convertToInstance = function (resp) { return new this(resp.data); };

axios.defaults.transformRequest = [
  CaseConverters.camelToSnakeCaseTransformer, ...axios.defaults.transformRequest
];
axios.defaults.transformResponse = [
  ...axios.defaults.transformResponse, CaseConverters.snakeToCamelCaseTransformer
];

/** The base resource parent class for API resources */
class BaseResource {
  static baseUrl            = '';
  static connection         = axios;
  static urlTransformations = ['id'];

  constructor(data) {
    this.setData(data);
    this.errors = [];
  }

  // to be overwritten by child classes
  attributes() {
    return {};
  }

  setData(data) {
    Object.assign(this, data);

    return this;
  }

  setErrors(err) {
    this.errors = err.response.data.errors;

    throw 'Save encountered errors';
  }

  save() {
    this.errors = [];

    if (this.id) {
      return this.constructor.update(this.attributes()).then(this.setData.bind(this));
    }
    else {
      return axios.post(this.constructor.transformedCollectionUrl(this), this.attributes()).then((resp) => {
        return Object.assign(this, resp.data);
      });
    }
  }

  reload() {
    return this.constructor.get({ id: this.id }).then(this.setData.bind(this));
  }

  // class level operators

  /**
   * @param { string } name - The name to use for the function.
   * @param { string } [pathToAppend] - The path to add to the resource's base URL
   *
   * @description Adds a class function for making a GET request to resource
   * collection route with a specified path suffix. If pathToAppend isn't provided,
   * then it will instead use the name as the value for both. When used this way,
   * the name will be snake_cased. NOTE: We may want to allow specification of the
   * casing in the future with an options param, or even merge this with the
   * member-level helper and make `{ on: <collection/member> }` an argument, too.
   *
   *
   *
   *
   * Example usage:
   *
   * ```javascript
   *
   * class Reference {
   *   static baseUrl = '/lawyers/:lawyerId/references/:id.json';
   *   static urlTransformations = ['lawyerId', 'id'];
   * }
   *
   * Reference.addGetCollectionRoute('approved');
   * Reference.addGetCollectionRoute('foo', '/foo_bar');
   *
   * Reference.approved({ lawyerId: 5 })
   * //> makes a GET request to `/lawyers/5/references/approved.json'
   *
   * Reference.foo({ lawyerId: 5 })
   * //> makes a GET request to `/lawyers/5/references/foo_bar`
   *```
   */
  static addGetCollectionRoute(name, pathToAppend) {
    this[name] = (params) => {
      pathToAppend          = pathToAppend || `/${snakeCase(name)}`;
      const fullpath        = this.baseUrl.replace(':id', '').replace('.', pathToAppend + '.');
      const transformedPath = this.transformUrl(fullpath, params);

      return axios.get(transformedPath, { params: params }).then(digIntoData).then((coll) => {
        return coll.map(resource => new this(resource));
      });
    };
  }

  static addPostRoute(name, pathToAppend) {
    this[name] = (data) => {
      pathToAppend          = pathToAppend || `/${snakeCase(name)}`;
      const fullpath        = this.baseUrl.replace('.', pathToAppend + '.');
      const transformedPath = this.transformUrl(fullpath, data);

      return axios.post(transformedPath, data).then(convertToInstance.bind(this));
    };
  }

  static addPutRoute(name, pathToAppend) {
    this[name] = (data) => {
      pathToAppend          = pathToAppend || `/${snakeCase(name)}`;
      const fullpath        = this.baseUrl.replace('.', pathToAppend + '.');
      const transformedPath = this.transformUrl(fullpath, data);

      return axios.put(transformedPath, data).then(convertToInstance.bind(this));
    };
  }

  static query(params, url) {
    const Resource = this;

    params = params || {};
    url = url || this.transformedCollectionUrl(params);

    return axios({
      method: 'get',
      url: url,
      params: params,
      transformResponse: [
        ...axios.defaults.transformResponse,
        function (data) {
          if (data instanceof Array) {
            return data.map((d) => { return new Resource(d); });
          }

          return data;
        }
      ]
    }).then(digIntoData);
  }

  static queryData(params, url) {
    const Resource = this;

    params = params || {};
    url = url || this.transformedNestedCollectionUrl(params);

    return axios({
      method: 'get',
      url: url,
      params: params,
      transformResponse: [
        ...axios.defaults.transformResponse,
        function (data) {
          if (data.resources && data.resources instanceof Array) {
            data.resources.map((d) => { return new Resource(d); });
          }

          return data;
        }
      ]
    }).then(digIntoData);
  }

  static save(data) {
    return axios.post(this.transformedCollectionUrl(data), data).then(convertToInstance.bind(this));
  }

  static postForm(data) {
    return axios.postForm(this.transformedCollectionUrl(data), data).then(convertToInstance.bind(this));
  }

  static get(params) {
    return axios.get(this.transformedMemberUrl(params), { params: params }).then(convertToInstance.bind(this));
  }

  static update(data) {
    return axios.put(this.transformedMemberUrl(data), data).then(convertToInstance.bind(this));
  }

  static putForm(data, config) {
    return axios.putForm(this.transformedMemberUrl(data), data, config).then(convertToInstance.bind(this));
  }

  static delete(params) {
    return axios.delete(this.transformedMemberUrl(params)).then(digIntoData);
  }

  static transformedCollectionUrl(data = {}) {
    return this.transformedMemberUrl(data).replace('/:id', '');
  }

  static transformedNestedCollectionUrl(data) {
    return this.transformedMemberUrl(data).replace('/:id', '/index_data');
  }

  static transformedMemberUrl(data) {
    return this.transformUrl(this.baseUrl, data);
  }

  static transformUrl(originUrl, data) {
    return reduce(this.urlTransformations, (url, mod) => {
      if (data[mod]) { return url.replace(`:${mod}`, data[mod]); }
      return url;
    }, originUrl);
  }
}

export default BaseResource;
