<template>
  <div :class="['top-notification', severityClasses[notification.severity]]" v-show="show">
    {{ notification.message }}
  </div>
</template>

<script>
import useNotificationStore from 'vue-app/stores/notification';
import { mapState } from 'pinia';

export default {
  name: 'Notification',

  data() {
    return {
      severityClasses: {
        info: 'notification-panel-info',
        warn: 'notification-panel-warn',
        success: 'notification-panel-success',
        error: 'notification-panel-error'
      }
    };
  },

  computed: {
    ...mapState(useNotificationStore, ['notification']),

    show() {
      return this.notification.message && this.notification.message.length;
    }
  }
};
</script>
