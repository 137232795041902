<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="none"><g stroke="#AF2E8D" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" :clip-path="absoluteUrl('mesh-globe-a')"><path fill="#fff" d="M24 42c9.941 0 18-8.059 18-18S33.941 6 24 6 6 14.059 6 24s8.059 18 18 18" /><path fill="#fff" d="M16.5 24c0 7.024 2.5 13.298 6.427 17.53a1.457 1.457 0 0 0 2.146 0C29 37.296 31.5 31.023 31.5 24S29 10.703 25.073 6.47a1.457 1.457 0 0 0-2.146 0C19 10.704 16.5 16.977 16.5 24" /><path d="M7.024 18h33.952M7.024 30h33.952" /></g><defs><clipPath id="mesh-globe-a"><path fill="#fff" d="M0 0h48v48H0z" /></clipPath></defs></svg>
</template>

<script>
export default {
  name: 'SvgMeshGlobe',

  methods: {
    absoluteUrl: function (id) {
      return `url(${window.location.href}#${id})`;
    }
  }
};
</script>
