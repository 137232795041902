const timeIntervalOptions = [{ hour: '00:00', label: '12:00 AM' }, { hour: '00:30', label: '12:30 AM' }, { hour: '01:00', label: '1:00 AM' }, { hour: '01:30', label: '1:30 AM' }, { hour: '02:00', label: '2:00 AM' }, { hour: '02:30', label: '2:30 AM' }, { hour: '03:00', label: '3:00 AM' }, { hour: '03:30', label: '3:30 AM' }, { hour: '04:00', label: '4:00 AM' }, { hour: '04:30', label: '4:30 AM' }, { hour: '05:00', label: '5:00 AM' }, { hour: '05:30', label: '5:30 AM' }, { hour: '06:00', label: '6:00 AM' }, { hour: '06:30', label: '6:30 AM' }, { hour: '07:00', label: '7:00 AM' }, { hour: '07:30', label: '7:30 AM' }, { hour: '08:00', label: '8:00 AM' }, { hour: '08:30', label: '8:30 AM' }, { hour: '09:00', label: '9:00 AM' }, { hour: '09:30', label: '9:30 AM' }, { hour: '10:00', label: '10:00 AM' }, { hour: '10:30', label: '10:30 AM' }, { hour: '11:00', label: '11:00 AM' }, { hour: '11:30', label: '11:30 AM' }, { hour: '12:00', label: '12:00 PM' }, { hour: '12:30', label: '12:30 PM' }, { hour: '13:00', label: '1:00 PM' }, { hour: '13:30', label: '1:30 PM' }, { hour: '14:00', label: '2:00 PM' }, { hour: '14:30', label: '2:30 PM' }, { hour: '15:00', label: '3:00 PM' }, { hour: '15:30', label: '3:30 PM' }, { hour: '16:00', label: '4:00 PM' }, { hour: '16:30', label: '4:30 PM' }, { hour: '17:00', label: '5:00 PM' }, { hour: '17:30', label: '5:30 PM' }, { hour: '18:00', label: '6:00 PM' }, { hour: '18:30', label: '6:30 PM' }, { hour: '19:00', label: '7:00 PM' }, { hour: '19:30', label: '7:30 PM' }, { hour: '20:00', label: '8:00 PM' }, { hour: '20:30', label: '8:30 PM' }, { hour: '21:00', label: '9:00 PM' }, { hour: '21:30', label: '9:30 PM' }, { hour: '22:00', label: '10:00 PM' }, { hour: '22:30', label: '10:30 PM' }, { hour: '23:00', label: '11:00 PM' }, { hour: '23:30', label: '11:30 PM' }];

const timeIntervals = function () {
  return timeIntervalOptions;
};

const workHours = function () {
  return timeIntervalOptions.slice(16, 37);
};

export { timeIntervals, workHours };
