<template>
  <div>
    <scheduling-modal
      v-if="isScheduling"
      ref="schedulingModal"
      :appointment="appointment"
      :possible-participants="possibleApptParticipants"
      :selected-participants="selectedApptParticipants"
      :on-submit="scheduleAppointment">
    </scheduling-modal>

    <choose-time-modal
      v-if="isConfirming"
      ref="confirmingModal"
      :appointment="appointment"
      :on-suggest-alternative-times="startUpdatingAppt"
      :on-request-cancellation="startCancellingAppt"
      :on-submit="confirmAppointment">
    </choose-time-modal>

    <scheduling-modal
      v-if="isUpdating"
      ref="updatingModal"
      :appointment="appointment"
      :on-cancel-appointment="startCancellingAppt"
      :on-submit="updateAppointment">
    </scheduling-modal>

    <rescheduling-modal
      v-if="isRescheduling"
      ref="reschedulingModal"
      :consultation="consultation"
      :appointment="appointment"
      :other-participants="otherApptParticipants"
      :on-submit="rescheduleAppointment">
    </rescheduling-modal>

    <cancelling-modal
      v-if="isCancelling"
      ref="cancellingModal"
      :appointment="appointment"
      :other-participants="otherApptParticipants"
      :on-submit="cancelAppointment">
    </cancelling-modal>
  </div>
</template>

<script>
import ChooseTimeModal from 'vue-app/marketplace/shared/appointments/choose-time-modal.vue';
import SchedulingModal from 'vue-app/marketplace/shared/appointments/scheduling-modal.vue';
import ReschedulingModal from 'vue-app/marketplace/shared/appointments/rescheduling-modal.vue';
import CancellingModal from 'vue-app/marketplace/shared/appointments/cancelling-modal.vue';
import Appointment from 'resources/marketplace/appointment.js';
import Consultation from 'resources/marketplace/consultation.js';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import useAppointmentWatcherStore from 'vue-app/stores/marketplace/appointment-watcher.js';
import { mapState, mapActions } from 'pinia';

export default {
  name: 'AppointmentModals',

  components: {
    ChooseTimeModal,
    SchedulingModal,
    ReschedulingModal,
    CancellingModal
  },

  emits: ['onSuccess'],

  data() {
    return {
      appointment: null,
      consultation: null,
      possibleApptParticipants: [],
      selectedApptParticipants: [],
      isScheduling: false,
      isConfirming: false,
      isUpdating: false,
      isRescheduling: false,
      isCancelling: false
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { user: 'currentUser' }),

    otherApptParticipants() {
      if (!this.appointment) { return []; }
      return this.appointment.participantsOtherThan(this.user).map(ap => ap.participant);
    }
  },

  methods: {
    ...mapActions(useAppointmentWatcherStore, { broadcastAppointmentsChanged: 'triggerUpdate' }),

    startSchedulingAppt(participants, selectedParticipants = []) {
      this.appointment = Appointment.newAppointment();
      this.possibleApptParticipants = [participants].flat();
      if (this.possibleApptParticipants.length === 1) {
        this.selectedApptParticipants = this.possibleApptParticipants;
      }
      else {
        this.selectedApptParticipants = [selectedParticipants].flat();
      }

      return Consultation.scheduledTimesFor(this.user).then((conflictingTimes) => {
        this.appointment.conflictingTimes.consultations = conflictingTimes;
        this.isScheduling = true;
        this.$nextTick(() => { this.$refs.schedulingModal.initModal(true); });
      });
    },

    scheduleAppointment(appointment, availability, selectedParticipants) {
      return appointment.submitSuggestedTimes(this.user, availability, selectedParticipants).then(this.processSuccess);
    },

    startConfirmingAppt(appointmentId) {
      Appointment.get({ id: appointmentId }).then((appointment) => {
        this.appointment = appointment;
        this.isConfirming = true;
        this.$nextTick(() => { this.$refs.confirmingModal.initModal(); });
      });
    },

    stopConfirmingAppt() {
      if (!this.isConfirming) { return; }

      this.$refs.confirmingModal.closeModal();
      this.isConfirming = false;
    },

    confirmAppointment(timeslot) {
      const params = {
        id: this.appointment.id,
        appointment: { consultationsAttributes: [{ startsAt: timeslot.asDate() }] }
      };
      return Appointment.update(params).then(this.processSuccess);
    },

    startUpdatingAppt(appointmentId) {
      this.stopConfirmingAppt();

      Appointment.get({ id: appointmentId }).then((appointment) => {
        this.appointment = appointment;
        this.isUpdating = true;
        this.$nextTick(() => { this.$refs.updatingModal.initModal(); });
      });
    },

    stopUpdatingAppt() {
      if (!this.isUpdating) { return; }

      this.$refs.updatingModal.closeModal();
      this.isUpdating = false;
    },

    updateAppointment(appointment, availability) {
      const participant = appointment.participantFor(this.user);
      return appointment.updateSuggestedTimes(participant, availability).then(this.processSuccess);
    },

    startReschedulingAppt(appointmentId, consultationId) {
      Appointment.get({ id: appointmentId }).then((appointment) => {
        this.appointment = appointment;
        this.consultation = appointment.consultationsAttributes.find((c) => c.id === consultationId) || {};
        this.consultation.appointment = this.appointment;
        this.isRescheduling = true;
        this.$nextTick(() => { this.$refs.reschedulingModal.initModal(); });
      });
    },

    rescheduleAppointment(reason, consultation, availability) {
      const appointment = consultation.appointment;
      const participant = appointment.participantFor(this.user);
      return appointment.reschedule(reason, consultation, availability, participant).then(this.processSuccess);
    },

    startCancellingAppt(appointmentId) {
      this.stopConfirmingAppt();
      this.stopUpdatingAppt();

      Appointment.get({ id: appointmentId }).then((appointment) => {
        this.appointment = appointment;
        this.isCancelling = true;
        this.$nextTick(() => { this.$refs.cancellingModal.initModal(); });
      });
    },

    cancelAppointment(appointment, reason) {
      const participant = appointment.participantFor(this.user);
      return appointment.cancel(reason, participant).then(this.processSuccess);
    },

    processSuccess() {
      this.broadcastAppointmentsChanged();
      this.$emit('on-success');
    }
  }
};
</script>
