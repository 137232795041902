<template>
  <div class="messaging-center-list-item">
    <div class="item-photo">
      <profile-photo :user="participant" :small="true"></profile-photo>
    </div>

    <div class="item-content">
      <div class="size-text-13px">
        <span class="semibold-weight">{{ participant.fullName }}<span v-if="isCurrentUser"> (You)</span></span>

        <loading-section name="projects" class="inline-block">
          <span class="left-5" v-if="shouldLink">(<router-link :to="`/lawyers/${participant.id}`">View Profile</router-link>)</span>
        </loading-section>
      </div>
    </div>
  </div>
</template>

<script>
import ProfilePhoto from 'vue-app/shared/components/profile-photo.vue';
import RouterLink from 'vue-app/transitional/router-link.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'ConversationParticipants',

  components: {
    ProfilePhoto,
    RouterLink,
    LoadingSection
  },

  props: {
    participant: {
      type: Object,
      required: true
    },

    userClass: {
      type: String,
      required: true
    },

    sharedMatters: {
      type: Array,
      required: true
    }
  },

  computed: {
    ...mapState(useCurrentUserStore, { user: 'currentUser' }),

    isCurrentUser() {
      return this.user.klass === this.participant.klass && this.user.id === this.participant.id;
    },

    shouldLink() {
      if (this.userClass === 'lawyer' || this.participant.klass !== 'Lawyer') { return false; }
      if (this.userClass === 'admin') { return true; }

      return this.sharedMatters.some(m => m.participantId === this.participant.id && m.hasSharedMatters);
    }
  }
};
</script>
