import BaseResource from 'resources/base-resource';
import { find, pick, result } from 'lodash';

class Review extends BaseResource {
  static baseUrl = '/reviews/:id.json';

  constructor(data) {
    super(data);

    this.form = {};
    this.optOut = false;
  }

  attributes() {
    return pick(this, ['matterId', 'form', 'optOut']);
  }

  responseToQuestion(slug) {
    return result(find(this.responses, { 'slug': slug }), 'response');
  }

  quote() {
    // This assumes that Question 2 has the quote
    return this.responseToQuestion('q2');
  }

  companySize() {
    // This assumes that Question 5 has the company size
    return this.responseToQuestion('q5');
  }

  workPerformed() {
    return this.responseToQuestion('q3');
  }

  hasQuote() {
    const quote = this.quote();
    return quote && quote.length;
  }

  hasCompanySize() {
    const companySize = this.companySize();
    return companySize && companySize.length;
  }

  hasWorkPerformed() {
    const workPerformed = this.workPerformed();
    return workPerformed && !Array.isArray(workPerformed);
  }
}

export default Review;
