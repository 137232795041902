<template>
  <div>
    <div class="modal-header">
      Who would you like to schedule with?
    </div>

    <div class="appointment-container">
      <div v-for="(participant, index) in possibleParticipants">
        <hr class="kenny-hr" v-if="index !== 0">

        <div class="row">
          <div class="col-sm-8 col-md-9 text-ellipsis photo-overflow-container">
            <profile-photo :user="participant"></profile-photo>
            <span class="semibold-weight left-20">{{ participant.fullName }}{{ participant.lawFirmName ? ` (${participant.lawFirmName})` : '' }}</span>
          </div>

          <div class="col-sm-4 col-md-3 photo-overflow-container">
            <div class="top-10">
              <button type="button" class="nv-button-blue smaller no-transform" @click="removeParticipant(index)" v-if="isParticipantSelected(participant)">Selected</button>

              <button type="button" class="nv-button-white smaller no-transform" @click="addParticipant(participant)" v-else>Select for Call</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfilePhoto from 'vue-app/shared/components/profile-photo.vue';

export default {
  name: 'StepParticipants',

  components: {
    ProfilePhoto
  },

  props: {
    possibleParticipants: {
      type: Array,
      default: () => []
    },

    selectedParticipants: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    isParticipantSelected(participant) {
      return this.selectedParticipants.includes(participant);
    },

    addParticipant(participant) {
      this.selectedParticipants.push(participant);
    },

    removeParticipant(index) {
      this.selectedParticipants.splice(index, 1);
    }
  }
};
</script>
