<template>
  <div>
    <button v-if="isSelected" type="button" class="nv-button-blue smaller no-transform" @click="deselect">{{ label }}</button>

    <button v-else type="button" class="nv-button-white smaller no-transform" @click="select" :disabled="!isAvailable">{{ label }}</button>
  </div>
</template>

<script>
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'AvailabilitySelectionHour',

  props: {
    timeslot: {
      type: Object,
      required: true
    },

    availability: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState(useCurrentUserStore, { ianaTimeZone: 'ianaTimeZone' }),

    label() {
      return this.timeslot.label({ timeZone: this.ianaTimeZone });
    },

    isAvailable() {
      return this.availability.isAvailable(this.timeslot);
    },

    isSelected() {
      return this.availability.isSelected(this.timeslot);
    }
  },

  methods: {
    select() {
      this.availability.add(this.timeslot);
    },

    deselect() {
      this.availability.remove(this.timeslot);
    }
  }
};
</script>
