import axios from 'axios';
import BaseResource from 'resources/base-resource';

class Proposal extends BaseResource {
  static baseUrl = '/proposals/:id.json';

  static track(data) {
    const url = `${this.baseUrl.replace(':id.json', data.id)}/track.json`;
    return axios.put(url, data);
  }

  isSent() {
    return !!this.sentAt;
  }

  orderedLawyers() {
    if (this.lawyerOrder.length > 0) {
      return this.lawyerOrder.map((lawyerId) => {
        return this.possibleLawyers.find(pl => pl.lawyerId === lawyerId);
      });
    }
    else {
      return this.possibleLawyers;
    }
  }

  showClosedProposalLawyers() {
    return this.possibleLawyers.length === 0 &&
      (this.status === 'admin_closed' || this.status === 'sent_to_client');
  }
}

export default Proposal;
