<template>
  <div class="size-text-13px">
    <div class="row">
      <div class="col-sm-5 dark-gray-text">
        Use the calendar below to select times you’re available. You can select times on multiple days.
      </div>

      <div class="col-sm-7 col-md-5 col-md-offset-2 top-20-xs">
        <time-zone-editor></time-zone-editor>
      </div>
    </div>

    <div class="top-20 bottom-30">
      <hr class="kenny-hr no-margin">
    </div>

    <div class="row tight-columns">
      <div class="col-xs-3 col-sm-2">
        <button type="button" class="prevnext-button hidden-xs" @click="movePreviousDates(3)" :disabled="!canMovePrevious"><svg-icon name="arrow-left" class="base-icon"></svg-icon></button>

        <button type="button" class="prevnext-button visible-xs-inline-block" @click="movePreviousDates(1)" :disabled="!canMovePrevious"><svg-icon name="arrow-left" class="base-icon"></svg-icon></button>
      </div>

      <div class="col-xs-6 col-sm-8 size-text-base semibold-weight text-center top-15">
        {{ firstDisplayDate }}<span class="hidden-xs"> – {{ lastDisplayDate }}</span>
      </div>

      <div class="col-xs-3 col-sm-2 text-right">
        <button type="button" class="prevnext-button hidden-xs" @click="moveNextDates(3)"><svg-icon name="arrow-right" class="base-icon"></svg-icon></button>

        <button type="button" class="prevnext-button visible-xs-inline-block" @click="moveNextDates(1)"><svg-icon name="arrow-right" class="base-icon"></svg-icon></button>
      </div>
    </div>

    <div class="row top-30 tight-columns">
      <div v-for="(date, index) in dates" :class="['col-sm-4', { 'hidden-xs': index > 0 }]">
        <availability-selection-day
          :date="date"
          :timeslots="timeslotsFor(date)"
          :availability="availability">
        </availability-selection-day>
      </div>
    </div>

    <div class="semibold-weight top-10">
      <button type="button" class="pseudo-link-blue" @click="toggleTimesExpanded">Show {{ expanded ? 'Fewer' : 'More' }} Times</button>
    </div>

    <form role="form" class="a-form top-30" novalidate>
      <label for="appointment-note">Include note with availability: <span class="normal-weight dark-gray-text">(optional)</span></label>

      <textarea name="appointmentNote" id="appointment-note" class="form-control" placeholder="Note" rows="2" v-model="availability.note"></textarea>
    </form>
  </div>
</template>

<script>
import AvailabilitySelectionDay from 'vue-app/marketplace/shared/appointments/availability-selection-day.vue';
import TimeZoneEditor from 'vue-app/marketplace/shared/appointments/time-zone-editor.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import Timeslot from 'services/marketplace/timeslot.js';
import useCurrentUserStore  from 'vue-app/stores/current-user.js';
import { timeIntervals, workHours } from 'vue-app/shared/misc/shared-lists/time-intervals.js';
import { range } from 'lodash';
import { mapState } from 'pinia';
import { DateTime } from 'luxon';

export default {
  name: 'AvailabilitySelector',

  components: {
    AvailabilitySelectionDay,
    TimeZoneEditor,
    SvgIcon
  },

  props: {
    availability: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      timeslots: [],
      workTimes: workHours().map(w => w.hour),
      earliestVisibleDate: DateTime.now(),
      expanded: false
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { ianaTimeZone: 'ianaTimeZone' }),

    today() {
      return DateTime.now().setZone(this.ianaTimeZone);
    },

    dates() {
      return range(3).map(offset => this.earliestVisibleDate.plus({ days: offset }));
    },

    firstDisplayDate() {
      return this.dates[0].toFormat('MMMM d');
    },

    lastDisplayDate() {
      return this.dates[2].toFormat('MMMM d');
    },

    canMovePrevious() {
      return this.today.startOf('day') < this.dates[0].startOf('day');
    },

    timesByDate() {
      let tsInZone;
      let isoDate;

      return this.timeslots.reduce((obj, timeslot) => {
        tsInZone = timeslot.inZone(this.ianaTimeZone || 'UTC');
        isoDate = tsInZone.date();

        if (!this.expanded && this.timeOutsideOfWorkHours(tsInZone)) { return obj; }

        obj[isoDate] = obj[isoDate] || [];
        obj[isoDate].push(timeslot);

        return obj;
      }, {});
    }
  },

  mounted() {
    this.reset();
  },

  methods: {
    reset() {
      this.expanded = false;
      this.earliestVisibleDate = this.today;
      this.initializeDays(this.today);
    },

    initializeDays(startDate) {
      this.timeslots = [];

      // really only need a range of 0, 1, and 2, but we include -1 and 3 to
      // preload the previous and next days. This allows us to change the
      // timezone and already have the updated timeslots in memory
      range(-1, 4).forEach(offset => {
        this.initializeDate(startDate.plus({ days: offset }));
      });
    },

    initializeDate(date) {
      let timeComponents;

      timeIntervals().forEach(time => {
        timeComponents = time.hour.split(':');
        this.timeslots.push(new Timeslot(date.set({
          hour: timeComponents[0], minutes: timeComponents[1], seconds: 0, milliseconds: 0
        })));
      });
    },

    timeOutsideOfWorkHours(timeslot) {
      return !this.workTimes.includes(timeslot.hour());
    },

    updateDateRange(offset) {
      this.earliestVisibleDate = this.earliestVisibleDate.plus({ days: offset });
      this.initializeDays(this.earliestVisibleDate);
    },

    movePreviousDates(offset) {
      if (!this.canMovePrevious) { return; }
      this.updateDateRange(offset * -1);
    },

    moveNextDates(offset) {
      this.updateDateRange(offset);
    },

    toggleTimesExpanded() {
      this.expanded = !this.expanded;
    },

    timeslotsFor(date) {
      return this.timesByDate[date.toFormat('y-LL-dd')] || [];
    }
  }
};
</script>
