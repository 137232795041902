<template>
  <svg viewBox="0 -36 240 240" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient :id="uniqueId" x1="0%" y1="0%" x2="0%" y2="100%"><stop offset="0" stop-color="#af2e8d" /><stop offset="1" stop-color="#1484c9" /></linearGradient></defs><path d="M143.904 85.098l-22.915 23.387a1.5 1.5 0 0 1-2.15 0L95.922 85.098a1.576 1.576 0 0 1 0-2.195l22.915-23.387a1.498 1.498 0 0 1 2.15 0l22.916 23.387a1.573 1.573 0 0 1 0 2.195m5.36-81.11L79.47 75.22c-4.753 4.849-4.753 12.713 0 17.562l27.377 27.94c.593.607.593 1.59 0 2.195l-23.81 24.303a1.502 1.502 0 0 1-2.153 0L20.017 85.098a1.573 1.573 0 0 1 0-2.195l60.868-62.121a1.5 1.5 0 0 1 2.152 0l11.856 12.101a1.499 1.499 0 0 0 2.151.002l9.84-10.043c.595-.607.595-1.59.002-2.195L90.565 3.989a11.994 11.994 0 0 0-17.207 0L3.563 75.219c-4.751 4.85-4.751 12.714 0 17.563l69.795 71.23a11.994 11.994 0 0 0 17.207 0l69.793-71.23c4.751-4.85 4.751-12.713 0-17.562L132.98 47.28a1.575 1.575 0 0 1 0-2.197l23.812-24.303a1.5 1.5 0 0 1 2.151 0l60.866 62.123a1.573 1.573 0 0 1 0 2.195l-60.866 62.122a1.5 1.5 0 0 1-2.15 0l-11.904-12.148a1.502 1.502 0 0 0-2.152 0l-9.838 10.043a1.573 1.573 0 0 0 0 2.195l16.365 16.702a11.996 11.996 0 0 0 17.208 0l69.791-71.23c4.752-4.85 4.752-12.713 0-17.562L166.472 3.989a11.996 11.996 0 0 0-17.208 0" :fill="absoluteUrl" /></svg>
</template>

<script>
import { uniqueId } from 'lodash';

export default {
  name: 'SvgPrioriGradient',

  data() {
    return {
      uniqueId: uniqueId('svg-')
    };
  },

  computed: {
    absoluteUrl() {
      return `url(${window.location.href}#${this.uniqueId})`;
    }
  }
};
</script>
